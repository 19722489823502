@import '../variables/variables.less';


/* common properties */
#bottom,
#footer {
	background-color: @FooterBackground;

	.row > * {
		@media @tablet {
			padding-left: 20px;
		}
		@media @desktop {
			padding-left: 20px;
			padding-right: 20px;
		}		
	}

	.row {
		.social-connect {
			@media @tablet, @desktop {
				padding-left: 32px;
				padding-right: 32px;
			}
		}
	}

	h4 {
		color: @FooterTitle-v2;

		@media @mobile {
			 color: @Grey-10;
		}
	}

	p, a {
		color: @FooterText-v2;
	}
	.social-connect a {
		color: @FooterTitle-v2;
	}

	.social-connect {
		img {
			&.avg-logo {
				margin-bottom: 32px;
				@media @mobile {
					margin-bottom: 0;
			   }
			}
		}
	}
}

/* bottom links */
#bottom {

	.container {
		padding-top: 88px;
		@media @mobile {
			padding-top: 8px;
		}

		@media @tablet, @desktop {
			border-bottom: 1px solid @FooterBorder-v2;
		}
	}
	.row {
		align-items: stretch;
	}

	h4 {
		@media @tablet, @desktop {
			margin-bottom: 0;
			min-height: 50px;
			font-size: 16px;
		}
		@media @mobile {
			margin: 24px 0;
			font-size: 16px;
		}
	}

	/* Listed Links component - footer links design */
	.footer-links {

		@media @tablet, @desktop {
			flex: 1 0 18%;


			&:nth-of-type(4) {
				margin-right: 30px; // space after the last column
			}
		}
		ul {
			@media @tablet, @desktop {
				padding-bottom: 30px;
			}
			li {
				padding-bottom: 0;
			}
			a {
				font-size: 14px;
			}
		}

		@media @mobile {
			cursor: pointer;
			border-bottom: 1px solid @FooterBorder;

			ul {
				a {
					display: block;
				}
			}
			.footer-links-content {
				display: none;
			}
			h4 span {
				float: right;
				width: 24px;
				height: 24px;
				margin-top: 10px;
				background: url("../i/avg/img/components/footer/footer-arrow-v2.svg") no-repeat 0 0;
			}
			&.active-column {

				h4 span {
					transform: rotate(180deg);
				}
			}
		}
	}
}

/* footer */
#footer {

	.container {
		padding-top: 32px;
		padding-bottom: 32px;

		@media @mobile {
			padding-top: 16px;
			padding-bottom: 32px;
			display: flex;
			flex-direction: column;

			.footer-top-row {order: 1;}
			.termination {order: 2;}
			.copyright-row {order: 3;}
		}
	}

	.row {
		display: block;
	}

	.footer-top {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		@media @mobile {
			justify-content: space-between;
			border-bottom: 1px solid @FooterBorder;
			padding-bottom: 16px;
		}
	}

	// logo
	.logo {
		img {
			width: 96px;

			@media @mobile {
				margin-bottom: 15px;
			}
		}
	}

	// DE Termination Link (Unsubscribe)
	.termination {
		.button {
			margin-bottom: 24px;

			@media @mobile {
				margin-bottom: 0;
				margin-top: 16px;
			}
		}
	}

	// copyright
	.copyright {
		margin-top: 16px;

		@media @desktop {
			flex: 3 0 auto;
		}

		/* @media @mobile {
			border-top: 1px solid @FooterBorder;
			padding-top: 16px;
		} */

		p {
			margin-bottom: 0;
			color: @Grey-7;

			@media @mobile {
				text-align: left;
			}

			a {
				color: @Grey-7;
			}

			button.ot-sdk-show-settings {
				color: @Grey-7;
				border: none;
				background: none;
				padding: 0px;
				font-size: 12px;
				&:hover {
					background: none;
					text-decoration: underline;
				}
			}

			u {
				text-decoration: none;
			}
		}
	}

	.border {
		border-right: 1px solid @Grey-1;
	}

	/* my-account */
	.my-account {
		a {
			color: @White;
			text-decoration-line: underline;
		}
	}

	/* language selector */
	.language-selector {
	   margin-right: 24px;

	   @media @mobile {
		 order: 2;
		}

		.button {
			background-color: rgba(248, 249, 250, 0.1);
			border-radius: 20.5px;
			margin-bottom: 0;

			span {
				padding: 8px 6px;
				display: flex;
				align-items: center;

				img {
					margin-left: 8px;
				}
			}
			u {
				text-decoration: none;
				font-size: 12px;

				&.icon-flag {
					margin-right: 10px; // space between flag and country
				}
			}
		}
	}
}
