// Font face import

@font-face {
    font-family: 'Roboto';
    src: url('../o/Roboto-Light.woff') format('woff'), url('../o/Roboto-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 200;
}
@font-face {
    font-family: 'Roboto';
    src: url('../o/Roboto-Regular.woff') format('woff'), url('../o/Roboto-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'Roboto';
    src: url('../o/Roboto-Bold.woff') format('woff'), url('../o/Roboto-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}
