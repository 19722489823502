@import '../variables/variables.less';

/* AVG typography */


/* Body default */
body {
    font-family: @font-family-roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: @Text;
}

/* Body large */
.subtitle,
.text-large {
    font-size: 20px;
    line-height: 1.4;
}
/* Body small */
.text-small {
    font-size: 14px;
    line-height: 1.57;
}
/* Body x-small */
.text-x-small {
    font-size: 12px;
    line-height: 1.67;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

h1,
h2,
h3,
h4,
h5,
.like-h1,
.like-h2,
.like-h3,
.like-h4,
.like-h5 {
    font-weight: 700;
    color: @Heading;
    margin: 0;
}

h1,
.like-h1 {
    font-size: 48px;
    margin-bottom: 20px;
    line-height: 1.3;

    @media @mobile {
		font-size: 34px;
	}
}
h2,
.like-h2 {
    font-size: 40px;
    margin-bottom: 30px;
    line-height: 1.2;

    @media @mobile {
		font-size: 30px;
	}
}
h3,
.like-h3 {
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 1.25;
}
h4,
.like-h4 {
    font-size: 20px;
    margin-bottom: 15px;
    line-height: 1.4;
}
h5,
.like-h5 {
    font-size: 16px;
    margin-bottom: 7px;
    line-height: 1.4;
}

// subtitles
.sub-h1 {
    color: @Subheading;
    font-size: 20px;
    line-height: 1.4;
}

// paragraph
p {
    margin: 0 0 15px;

    &:last-of-type {
        margin-bottom: 30px;
    }
}

// lists
ul,
ol {
    margin: 0 0 20px;
    padding-left: 0;

    li {
        padding-bottom: 20px;
    }
}
ul {
    list-style: none;
}

ul ul,
ol ul,
ul ol,
ol ol {
    margin-bottom: 0;
}


dl {
  margin-top: 0;
  margin-bottom: 19px;
}
dt,
dd {
  line-height: 1.2;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777777;
}

address {
  margin-bottom: 19px;
  font-style: normal;
  line-height: 1.2;
}

// Hyperlink
a {
    color: @HyperlinkText;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    color: @HyperlinkText;
    text-decoration: underline;
  }
  a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  a.initial {
      color: initial;
  }
  a.initial:hover,
  a.initial:focus,
  a.initial:active {
      color: initial;
      text-decoration: none;
  }

  .academy-link {
    color: @HyperlinkText;

    &:hover {
      color: @HyperlinkText;
      text-decoration: underline;
    }
  }
