.notification-overlay-for-wrong-download {
	align-items: center;
	background-color: rgba(0, 0, 0, .8);
	bottom: 0;
	display: none;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 9000;

	@media only screen and (max-width: 768px) {
		overflow-y: scroll;
	}

	&.show-popup {
		display: flex;

		@media only screen and (max-width: 768px) {
			display: block;
		}
	}

	.popup-logo {
		position: absolute;
		top: 24px;
		left: 24px;
		height: 35px;
		width: auto;
	}

	.detected-pc,
	.detected-android,
	.detected-mac,
	.detected-ios,
	.install-pc,
	.install-android,
	.install-mac,
	.install-ios {
		display: none;
	}

	.button-label {
		display: none;
	}

	&.no-alternative-text {
		.alternative-text {
			display: none !important;
		}
	}

	&.alternative-text {
		.sub,
		.instead-no-alt {
			display: none !important;
		}
	}

	&.alternative-text-b {
		.alternative-text-c {
			display: none !important;
		}
	}

	&.alternative-text-c {
		.alternative-text-b {
			display: none !important;
		}
	}

	&.detected-others {
		.instead {
			display: none !important;
		}
	}

/* -------------------------------------------------------  */

	&.detected-windows.install-mac {
		.detected-pc.install-mac {display: inline;}
	}

	&.detected-windows.install-android {
		.detected-pc.install-android {display: inline;}
	}

	&.detected-windows.install-ios {
		.detected-pc.install-ios {display: inline;}
	}

/* -------------------------------------------------------  */

	&.detected-mac.install-windows {
		.detected-mac.install-pc {display: inline;}
	}

	&.detected-mac.install-android {
		.detected-mac.install-android {display: inline;}
	}

	&.detected-mac.install-ios {
		.detected-mac.install-ios {display: inline;}
	}

/* -------------------------------------------------------  */

	&.detected-android.install-windows {
		.detected-android.install-pc {display: inline;}
	}

	&.detected-android.install-mac {
		.detected-android.install-mac {display: inline;}
	}

	&.detected-android.install-ios {
		.detected-android.install-ios {display: inline;}
	}

/* -------------------------------------------------------  */

	&.detected-ios.install-windows {
		.detected-ios.install-pc {display: inline;}
	}

	&.detected-ios.install-mac {
		.detected-ios.install-mac {display: inline;}
	}

	&.detected-ios.install-android {
		.detected-ios.install-android {display: inline;}
	}

/* -------------------------------------------------------  */

	&.detected-unknown {
		&.install-windows {
			.detected-unknown.install-pc {
				display: inline;
			}
		}

		&.install-mac {
			.detected-unknown.install-mac {
				display: inline;
			}
		}

		&.install-android {
			.detected-unknown.install-android {
				display: inline;
			}
		}

		&.install-ios {
			.detected-unknown.install-ios {
				display: inline;
			}
		}
	}


/* -------------------------------------------------------  */
	&.detected-windows {
		.platform-icon.detected-pc {display: inline;}
	}

	&.detected-mac {
		.platform-icon.detected-mac {display: inline;}
	}

	&.detected-android {
		.platform-icon.detected-android {display: inline;}
	}

	&.detected-ios {
		.platform-icon.detected-ios {display: inline;}
	}
/* -------------------------------------------------------  */



/* -------------------------------------------------------  */
	&.install-windows {
		.button-label--windows {display: inline;}
	}

	&.install-mac {
		.button-label--mac {display: inline;}
	}

	&.install-android {
		.button-label--android {display: inline;}
	}

	&.install-ios {
		.button-label--ios {display: inline;}
	}
/* -------------------------------------------------------  */

	.close-popup {
		background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"%3E%3Cpath d="M13 13L1 1m12 0L1 13" stroke="%234D5566" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round"/%3E%3C/svg%3E');
		background-size: 12px;
		background-position: center;
		background-repeat: no-repeat;
		background-color: transparent;
		cursor: pointer;
		display: block;
		height: 40px;
		width: 40px;
		border-radius: 100%;
		position: absolute;
		right: 24px;
		top: 24px;
		border: 1px solid #c7cdd7;
		border-radius: 100%;
		transition: background-color 0.2s;
		&:hover {
			background-color: #c7cdd7;
		}
		&:focus {
			outline: none;
		}
	}

	.platform-icon {
		margin-bottom: 1rem;
		max-height: 45px;

		@media only screen and (max-width: 768px) {
			margin-top: 5px;
		}
	}

	.content {
		align-items: center;
		background-color: #ffffff;
		color: #000000;
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 550px;
		padding: 104px 24px 48px;
		position: relative;
		@media only screen and (min-width: 768px) {
			width: 430px;
		}
		.main-title,
		.main-subtitle {
			font-family: 'Roboto';
            font-weight: normal;
		}
		.main-title {
			display: block;
			max-width: 333px;
			margin: 15px auto 0;
			line-height: 1.5;
			font-size: 24px;
			font-weight: 700;
		}
		.main-subtitle {
			color: #4d5566;
		}
		.main-subtitle--last {
			display: inline-block;
			margin-top: 15px;
		}
	}

	.title {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 40px;
		text-align: center;

		@media only screen and (max-width: 768px) {
			max-width: 190px;
		}
	}

	.main {
		display: block;
		font-weight: 700;
		font-size: 20px;
		padding-bottom: 5px;
	}

	.button.button-large {
		font-size: 20px !important;
		line-height: 20px;
		margin-bottom: 25px;

		@media only screen and (max-width: 768px) {
			line-height: 17px !important;
			padding: 11px 15px 11px 15px !important;
		}
	}

	.button.button-flat {
		&[data-os] {
			&::before {
				content: '';
				display: inline-block;
				vertical-align: bottom;
				width: 24px;
				height: 24px;
				margin-right: 15px;
				background-repeat: no-repeat;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		&[data-os="windows"] {
			&::before {
				background-image: url(../i/ico/ico-platforms/ico-win-white.svg);
			}
		}
		&[data-os="mac"] {
			&::before {
				background-image: url(../i/ico/ico-platforms/ico-mac-white.svg);
			}
		}
		&[data-os="android"],
		&[data-os="ios"] {
			background-color: transparent;
			border: 0;
			padding: 0;
			&::before {
				display: none;
			}
			img {
				width: 225px;
			}
		}

		// outline button while not on over
		&.button-outline:not(:hover) {
			&[data-os="windows"] {
				&::before {
					background-image: url(../i/ico/ico-platforms/ico-win-green.svg);
				}
			}
			&[data-os="mac"] {
				&::before {
					background-image: url(../i/ico/ico-platforms/ico-mac-green.svg);
				}
			}
		}
	}

	.from-store {
		color: #5c707b;
		font-size: 14px;
		margin-bottom: 16px;
		margin-top: -20px;
		text-align: center;
	}

	.instead {
		font-family: 'Roboto';
		font-size: 16px;
		margin-bottom: 11px;
		text-align: center;
	}

	.back,
	.alt-download {
		position: relative;
		display: inline-block;
		margin-top: 10px;
		font-family: 'Roboto';
		font-size: 13px;
		padding: 5px 2px;
		color: #1e222a;
		text-decoration: none;
		&:hover {
			color: #3FA4FF;
		}
		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			border-bottom: 1px dashed currentColor;
		}
	}

	.alt-download {
		top: -20px;
		font-size: 16px;
		font-weight: 500;
	}

	.text-muted {
		color: #4d5566;
	}

	.custom-font {
		font-weight: bold;
	}
}

.notification-overlay-for-wrong-download--GLOWEB-3368 {
	&.detected-android,
	&.detected-ios,
	&.detected-mac {
		.close-popup {
			background-size: 16px;
			&:not(:hover) {
				border-color: transparent;
			}
		}
		.or-text {
			display: inline-block;
			margin: -15px 0 10px;
			color: #6D768D;
		}
		.download-for-text {
			display: inline;
		}
		.js-close.back {
			display: none;
		}

		.button.button-flat.button-outline {
			&[data-os="windows"],
			&[data-os="mac"] {
				position: relative;
				padding: 0 0 5px;
				border: 0;
				font-size: 16px;
				color: #4D5566;
				font-weight: 500;
				transition: color 0.2s;
				&:hover {
					background-color: transparent;
					color: #000;
					&::after {
						background-color: CurrentColor;
					}
				}
				&::before {
					display: none;
				}
				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					border-bottom: 2px dashed CurrentColor;
					transition: background-color 0.2s;
				}
			}
		}
	}
	&.detected-android {
		.or-text {
			margin-top: -25px;
		}
	}
	.or-text,
	.download-for-text {
		display: none;
	}
}
