.button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 0 15px;
}
.button:hover,
.button:focus,
.button.focus,
.button:active,
.button.active {
  text-decoration: none;
  background-image: none !important;
}
.button.button-green.button-large {
  background-image: -webkit-linear-gradient(top, #57c231 0%, #399e2d 100%);
  background-image: -o-linear-gradient(top, #57c231 0%, #399e2d 100%);
  background-image: linear-gradient(to bottom, #57c231 0%, #399e2d 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff57c231', endColorstr='#ff399e2d', GradientType=0);
  color: #ffffff;
}
.button.button-green.button-large:hover,
.button.button-green.button-large:focus,
.button.button-green.button-large.focus,
.button.button-green.button-large:active,
.button.button-green.button-large.active {
  background-color: #399e2d;
}
.button.button-gray {
  color: #ffffff;
  background-color: #778099;
}
.button.button-gray:hover,
.button.button-gray:focus,
.button.button-gray.focus,
.button.button-gray:active,
.button.button-gray.active {
  background-color: #636b80;
}

.button.button-green {
  background-image: -webkit-linear-gradient(top, #57c231 0%, #399e2d 100%);
  background-image: -o-linear-gradient(top, #57c231 0%, #399e2d 100%);
  background-image: linear-gradient(to bottom, #57c231 0%, #399e2d 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff57c231', endColorstr='#ff399e2d', GradientType=0);
  color: #ffffff;
}
.button.button-green,
.button.button-green,
.button.button-green.focus,
.button.button-green:active,
.button.button-green.active {
  background-color: #399e2d;
}

.button.button-mid {
  min-width: 177px;
  border-radius: 4px;
  padding: 9px 15px 9px 15px;
  font-size: 18px;
  letter-spacing: -0.03em;
  font-family: AVGSans_4, Verdana;
}

.button.button-orange.button-large,
.button.button-orange.button-mid,
.button.button-orange.button-small {
  background-image: -webkit-linear-gradient(top, #fe691f 0%, #ff471c 100%);
  background-image: -o-linear-gradient(top, #fe691f 0%, #ff471c 100%);
  background-image: linear-gradient(to bottom, #fe691f 0%, #ff471c 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffe691f', endColorstr='#ffff471c', GradientType=0);
  color: #ffffff;
}
.button.button-orange.button-large:hover,
.button.button-orange.button-large:focus,
.button.button-orange.button-large.focus,
.button.button-orange.button-large:active,
.button.button-orange.button-large.active {
  background-color: #ff471c;
}
.button.button-orange.button-mid:hover,
.button.button-orange.button-mid:focus,
.button.button-orange.button-mid.focus,
.button.button-orange.button-mid:active,
.button.button-orange.button-mid.active {
  background-color: #ff471c;
}
.button.button-orange.button-small:hover,
.button.button-orange.button-small:focus,
.button.button-orange.button-small.focus,
.button.button-orange.button-small:active,
.button.button-orange.button-small.active {
  background-color: #ff471c;
}
.button.button-blue.button-large {
  background-image: -webkit-linear-gradient(top, #40acff 0%, #249efc 100%);
  background-image: -o-linear-gradient(top, #40acff 0%, #249efc 100%);
  background-image: linear-gradient(to bottom, #40acff 0%, #249efc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff40acff', endColorstr='#ff249efc', GradientType=0);
  color: #ffffff;
}
.button.button-blue.button-large:hover,
.button.button-blue.button-large:focus,
.button.button-blue.button-large.focus,
.button.button-blue.button-large:active,
.button.button-blue.button-large.active {
  background-color: #249efc;
}
.button.button-darkblue.button-large {
  background-image: -webkit-linear-gradient(top, #00a1e7 0%, #008fce 100%);
  background-image: -o-linear-gradient(top, #00a1e7 0%, #008fce 100%);
  background-image: linear-gradient(to bottom, #00a1e7 0%, #008fce 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00a1e7', endColorstr='#ff008fce', GradientType=0);
  color: #ffffff;
}
.button.button-darkblue.button-large:hover,
.button.button-darkblue.button-large:focus,
.button.button-darkblue.button-large.focus,
.button.button-darkblue.button-large:active,
.button.button-darkblue.button-large.active {
  background-color: #00a1e7;
}
.button.button-black.button-large {
  background-image: -webkit-linear-gradient(top, #626c6a 0%, #434844 100%);
  background-image: -o-linear-gradient(top, #626c6a 0%, #434844 100%);
  background-image: linear-gradient(to bottom, #626c6a 0%, #434844 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff626c6a', endColorstr='#ff434844', GradientType=0);
  color: #ffffff;
}
.button.button-black.button-large:hover,
.button.button-black.button-large:focus,
.button.button-black.button-large.focus,
.button.button-black.button-large:active,
.button.button-black.button-large.active {
  background-color: #434844;
}
.button.button-grey.button-large {
  background-image: -webkit-linear-gradient(top, #e0eaf1 0%, #d5e1e7 100%);
  background-image: -o-linear-gradient(top, #e0eaf1 0%, #d5e1e7 100%);
  background-image: linear-gradient(to bottom, #e0eaf1 0%, #d5e1e7 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe0eaf1', endColorstr='#ffd5e1e7', GradientType=0);
  color: #5c707b;
}
.button.button-grey.button-large:hover,
.button.button-grey.button-large:focus,
.button.button-grey.button-large.focus,
.button.button-grey.button-large:active,
.button.button-grey.button-large.active {
  background-color: #d5e1e7;
}
.button.button-nevada.button-large {
  background-image: -webkit-linear-gradient(top, #5c707b 0%, #46555e 100%);
  background-image: -o-linear-gradient(top, #5c707b 0%, #46555e 100%);
  background-image: linear-gradient(to bottom, #5c707b 0%, #46555e 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5c707b', endColorstr='#ff46555e', GradientType=0);
  color: #ffffff;
}
.button.button-nevada.button-large:hover,
.button.button-nevada.button-large:focus,
.button.button-nevada.button-large.focus,
.button.button-nevada.button-large:active,
.button.button-nevada.button-large.active {
  background-color: #46555e;
}
.button.button-nevada.button-large.button-outlined {
  background-image: none !important;
  border: 1px solid #5c707b !important;
  filter: none !important;
  color: #5c707b;
}
.button.button-nevada.button-large.button-outlined:hover,
.button.button-nevada.button-large.button-outlined:focus,
.button.button-nevada.button-large.button-outlined.focus,
.button.button-nevada.button-large.button-outlined:active,
.button.button-nevada.button-large.button-outlined.active {
  background-color: transparent !important;
}
.button.button-red.button-large {
  background-image: -webkit-linear-gradient(top, #f40e03 0%, #c9042a 100%);
  background-image: -o-linear-gradient(top, #f40e03 0%, #c9042a 100%);
  background-image: linear-gradient(to bottom, #f40e03 0%, #c9042a 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff40e03', endColorstr='#ffc9042a', GradientType=0);
  color: #ffffff;
}
.button.button-red.button-large:hover,
.button.button-red.button-large:focus,
.button.button-red.button-large.focus,
.button.button-red.button-large:active,
.button.button-red.button-large.active {
  background-color: #c9042a;
}
.button.button-dark-red.button-large {
  background-image: -webkit-linear-gradient(top, #bd1515 0%, #9f0909 100%);
  background-image: -o-linear-gradient(top, #bd1515 0%, #9f0909 100%);
  background-image: linear-gradient(to bottom, #bd1515 0%, #9f0909 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbd1515', endColorstr='#ff9f0909', GradientType=0);
  color: #ffffff;
}
.button.button-dark-red.button-large:hover,
.button.button-dark-red.button-large:focus,
.button.button-dark-red.button-large.focus,
.button.button-dark-red.button-large:active,
.button.button-dark-red.button-large.active {
  background-color: #9f0909;
}
.button.button-white.button-large {
  background-color: #ffffff;
  color: #000000;
}
.button.button-white.button-small {
  color: #ffffff;
  border: 1px solid #ffffff;
}
.button.button-white.button-small::before,
.button.button-white.button-small::after {
  border-color: #ffffff !important;
}
.button.button-blue.button-small {
  color: #006AC2;
  border: 1px solid #006AC2;
}
.button.button-blue.button-small::before,
.button.button-blue.button-small::after {
  border-color: #40acff !important;
}
.button.button-small {
  border-radius: 16px;
  font-size: 16px;
  padding: 6px 16px 6px 16px;
}
.button.button-small.arrow-left::before {
  content: "< ";
}
.button.button-small.arrow-left.icon-right::before {
  content: " ";
}
.button.button-small.arrow-left.icon-right::after {
  content: " <";
}
.button.button-small.arrow-right::before {
  content: "> ";
}
.button.button-small.arrow-right.icon-right::before {
  content: " ";
}
.button.button-small.arrow-right.icon-right::after {
  content: " >";
}
.button.button-small.top::before,
.button.button-small.top:before {
    font-family: AVGIconFont, Verdana;
    content: "\e606\e020";
}
.button.button-small.top.icon-right::before,
.button.button-small.top.icon-right:before {
    content: "";
}
.button.button-small.top.icon-right::after,
.button.button-small.top.icon-right:after {
    font-family: AVGIconFont, Verdana;
    content: "\e020\e606";
}
.button.button-small.bottom::before,
.button.button-small.bottom:before {
    font-family: AVGIconFont, Verdana;
    content: "\e600\e020";
}
.button.button-small.bottom.icon-right::before,
.button.button-small.bottom.icon-right:before {
    content: "";
}
.button.button-small.bottom.icon-right::after,
.button.button-small.bottom.icon-right:after {
    font-family: AVGIconFont, Verdana;
    content: "\e020\e600";
}
.button.button-small.tick::before,
.button.button-small.tick:before {
    font-family: AVGIconFont, Verdana;
    content: "\e605";
    line-height: 50%;
    padding-right: 7px;
}
.button.button-small.tick.icon-right::before,
.button.button-small.tick.icon-right:before {
    content: "";
}
.button.button-small.tick.icon-right::after,
.button.button-small.tick.icon-right:after {
    font-family: AVGIconFont, Verdana;
    content: "\e605";
    line-height: 50%;
    padding-left: 7px;
}
.button.button-small.plus::before,
.button.button-small.plus:before {
  font-family: AVGIconFont, Verdana;
  content: "\e604\e020";
}
.button.button-small.plus.icon-right::before,
.button.button-small.plus.icon-right:before {
  content: "";
}
.button.button-small.plus.icon-right::after,
.button.button-small.plus.icon-right:after {
  font-family: AVGIconFont, Verdana;
  content: "\e604";
}
.button.button-small.play-right::before,
.button.button-small.play-right:before {
  font-family: AVGIconFont, Verdana;
  content: "\e603\e020";
}
.button.button-small.play-right.icon-right::before,
.button.button-small.play-right.icon-right:before {
  content: "";
}
.button.button-small.play-right.icon-right::after,
.button.button-small.play-right.icon-right:after {
  font-family: AVGIconFont, Verdana;
  content: "\e020\e603";
}
.button.button-small.play-left::before,
.button.button-small.play-left:before {
  font-family: AVGIconFont, Verdana;
  content: "\e602\e020";
}
.button.button-small.play-left.icon-right::before,
.button.button-small.play-left.icon-right:before {
  content: "";
}
.button.button-small.play-left.icon-right::after,
.button.button-small.play-left.icon-right:after {
  font-family: AVGIconFont, Verdana;
  content: "\e020\e602";
}
.button.button-small.minus::before,
.button.button-small.minus:before {
  font-family: AVGIconFont, Verdana;
  content: "\e601\e020";
}
.button.button-small.minus.icon-right::before,
.button.button-small.minus.icon-right:before {
  content: "";
}
.button.button-small.minus.icon-right::after,
.button.button-small.minus.icon-right:after {
  font-family: AVGIconFont, Verdana;
  content: "\e601";
}
.button.button-large {
  border-radius: 4px;
  padding: 20px 15px 20px 15px;
  min-width: 200px;
  font-size: 18px;
  letter-spacing: -0.03em;
  font-family: AVGSans_4, Verdana;
}
@media (max-width: 768px) {
  .button.button-large {
    min-width: 100%;
  }
}
.button.button-large.button-text-large {
  padding: 17px 15px 17px 15px;
}
.button.button-large.button-small-only {
  padding: 21px 15px 21px 15px;
}
.button.button-large.button-small-only.two-lines,
.button.button-large.button-text-large.button-small-only.two-lines {
  padding: 12px 15px 12px 15px;
}
.button.button-large.button-content-only.button-text-large.two-lines {
  padding: 4px 15px 4px 15px;
}
.button.button-large.button-content-only.two-lines {
  padding: 10px 15px 10px 15px;
}
.button.button-large.button-text-large.two-lines {
  padding: 8px 15px 8px 15px;
}
.button.button-large.button-outlined {
  padding: 17px 14px 17px 14px;
}
.button.button-large.two-lines {
  padding: 11px 15px 11px 15px;
}
.button.button-large.two-lines.button-outlined {
  padding: 9px 14px 9px 14px;
}
.button.button-large > span {
  line-height: 20px;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
}
.button.button-large small {
  font-size: 14px;
  line-height: 18px;
  font-family: Verdana, sans-serif;
  display: block;
}
.button.button-large.button-text-large small {
  font-size: 16px;
}
.button.button-block {
  display: block;
  min-width: 0;
}
.button.button-shadow {
  box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.4);
  -msie-box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.4);
}
.button-block + .button-block {
  margin-top: 5px;
}
input[type="submit"].button-block,
input[type="reset"].button-block,
input[type="button"].button-block {
  width: 100%;
}

a.countdownBtnChange { display: none !important }

.button.hub-tag {
  margin: 0 0.4rem 0 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 0.4em 0.8em;
  line-height: 1.3;
  font-size: 0.9rem;
  transition: color 0.2s, background 0.2s;
}
.button.hub-tag.button-column {
  margin: 0 0 0.8em;
}
.button.hub-tag.button-blue {
  border-color: #0099bb;
  color: #0099bb;
  background: transparent;
}
.button.hub-tag.button-blue:hover {
  color: #fff;
  background: #0099bb;
}
@media (min-width: 768px) {
  .button.hub-tag.button-text-small {
    padding: 0.2rem 0.4rem;
    font-size: 0.72rem;
  }
}
.button.hub-tag .text-small {
  margin: 0;
  font-size: 100% !important;
  line-height: 1 !important;
}


.button-flat {
  background-color: #008941;
  color: #fff;
  font-size: 18px;
  padding: 14px 25px 16px;
  font-family: 'Roboto';
  border-radius: 6px;
  border: 1px solid #008941;
  transition: background-color 0.2s;
  &:hover {
    color: #fff;
    background-color: darken(#008941, 5%);
  }
  &.button-outline {
    transition: background-color 0.2s, color 0.2s;
    &:not(:hover) {
      color: #008941;
      background-color: transparent;
    }
  }
}
