@import '../variables/variables.less';

// Bottom
#system {
	background-color: #1e222a;
	padding-top: 100px;
	padding-bottom: 50px;

	@media @mobile {
		padding-top: 60px;
	}

	.row {
		border-bottom: 0.5px solid rgba(187, 193, 204, 0.4);
		padding-bottom: 20px;

		@media @mobile {
			padding: 0 20px 20px 20px;
		}
	}

	h4 {
		color: @White;
	}
	p, a {
        color: @FooterText;
        font-size: 13px;
        line-height: 1.85;
    }
    
    .separator {
        border-bottom: 1px solid fade(@Grey-1, 50%);
    }

    // languages
	.languages {
        padding-top: 20px;
        .separator;
	
		p {
            margin-bottom: 20px;
		}
	}
    
    // system requirements
	.system-requirements {
        .separator;

		p {
			display: flex;
			align-items: center;
			background-repeat: no-repeat;
			// background-image: url('../i/lp/lp-ppc_protection-free-v2/windows-logo.png');
			margin-bottom: 10px;
            min-height: 50px;
            
            img {
                height: 20px;
                margin-right: 10px;
                vertical-align: middle;
			}
			
			a {
				margin-left: 10px;
			}
		}
	}    
}