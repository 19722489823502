/* used on region selector */

.modal-open {
    overflow: hidden;
  }
  .modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8005;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }
  .modal-close {
      width:24px;
      height:24px;
      display:inline-block;
      background:url("../i/avg/img/components/modal/close.png") no-repeat 0 0;
      cursor:pointer;
      float:right;
      border: none;
  }
  .modal-close:hover,
  .modal-close:active,
  .modal-close:focus,
  .modal-close:target { opacity:.6 }

  .modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
  }
  .modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
  }
  .modal-content {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #999999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    outline: 0;
  }
  .modal-backdrop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: #000000;
  }
  .modal-backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .modal-backdrop.in {
    opacity: 0.6;
    filter: alpha(opacity=60);
  }
  .modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.2px;
  }
  .modal-header .close {
    margin-top: -2px;
  }
  .modal-title {
    margin: 0;
    line-height: 1.2;
  }
  .modal-body {
    position: relative;
    padding: 15px;
  }
  .modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
  }
  .modal-footer .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0;
  }
  .modal-footer .btn-group .btn + .btn {
    margin-left: -1px;
  }
  .modal-footer .btn-block + .btn-block {
    margin-left: 0;
  }
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }


  //EN-GB renewal modal
  #renewal-modal,
  #subscription-details-modal {
    background: rgba(7, 29, 43, 0.8);
    .modal-content {
      padding: 80px 104px;
      border: none;

    }
    .modal-header,.modal-body {
      padding: 0px;
      border: none;
    }

    .modal-header {
      margin-bottom: 24px;
      h5 {
        font-size: 32px;
        line-height: 48px;
      }
      .close {
        position: absolute;
        top: 40px;
        right: 32px;
        opacity: 1;
        margin: 0;
        float: none;
        padding: 16px;
        border: 1px solid #D1D5DB;
        box-sizing: border-box;
        border-radius: 4px;
        background: #fff;
        transition: all 200ms linear;

		@media (max-width: 767px) {
			top: 20px;
		}

        &:hover {
          background: rgb(239, 239, 239);
        }
        span {
          height: 24px;
          display: block;
        }
      }
    }
    .modal-body {
      .container-fluid {
        padding: 0;
      }
      .row {
        margin: 0;
      }
      p {
        font-size: 14px;
        line-height: 21px;
        color: #4B5563;
      }
      p:last-of-type {
        margin-bottom: 30px;
    }
    }
    .close-button {
      font-weight: bold;
      font-size: 18px;
      padding: 16px 24px;
      background: none;
      border: 1px solid #D1D5DB;
      border-radius: 6px;
      transition: all 200ms linear;
      &:hover {
        background: rgb(239, 239, 239);
      }
    }
  }


  // UK Compliance hotfix; I'm really sorry for this, but there was no other way :/


  @media (min-width: 480px) {
    .modal-dialog {
      width: 600px;
      margin: 50px auto;
    }
    .modal-content {
      -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }
    .modal-sm {
      width: 300px;
    }
  }
  @media (min-width: 768px) {
    .modal-lg {
      width: 1060px;
    }
  }

  .modal-full {
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 20000;
    .modal-body,
    .modal-content {
      height: 100%;
      padding: 0;
    }
  }

  #renewal-modal,
  #subscription-details-modal {
    @media (min-width: 220px) {
      .modal-dialog {
        width: 100%;
        .modal-content {
          padding: 80px 40px 40px;
        }
      }
    }
    @media (min-width: 768px) {
      .modal-dialog {
        width: 768px;
        .modal-content {
          padding: 80px 104px;
        }
      }
    }
    @media (min-width: 1060px) {
      .modal-dialog {
        width: 1060px;
      }
    }
  }


.info-button {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  background: url("../i/ico/ico-info-16x16-white.svg") no-repeat;
  position: relative;
  top: 3px;
  left: 3px;

  &:hover {
    background: url("../i/ico/ico-info-16x16-dark.svg") no-repeat;
  }
}
