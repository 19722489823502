@import '../variables/variables.less';
@import 'normalize.less';

/*
This is original bootstrap.css.less stripped from
- global generic styles
- bootstrap styles
*/

* {
    box-sizing: border-box;
}

html {
  font-family: sans-serif;
  scroll-behavior: smooth;
}

 body {
    background-color: @BodyBackground;
    margin: 0;
}

/* container width */

// .AVsizer is defined to make code transfer from Avast easier

// rectangle values
@rectangle-dimension: 600px;

.container,
.AVsizer {
    @media @desktop {
        max-width: 1360px;
        margin: auto;

        // padding for blocks that are not full width
        &:not(.full) {
            padding-left: 125px;
            padding-right: 125px;
        }
    }

    // color version
    &.grey {
      background-color: @Grey-6;
    }
    &.blue {
      background-color: @Blue-v2;
      color: @White;
    }
}

/* vertical center */
.vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

// banner element
.banner {
    background: transparent url(../i/components/rectangle-yellow-45.svg) 50% 0 no-repeat;
    margin-left: -20px;
    margin-right: -20px;
}

/* img */
img {
    max-width: 100%;
    vertical-align: middle;
}

/* to be removed */
.padded {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

/* Screen readers */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }


.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
  visibility: hidden;
}
.collapse.in {
  display: block;
  visibility: visible;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

/* close */
.close {
  float: right;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.close:hover,
.close:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

/* banner */
.banner {
  text-align: center;
}
@media (max-width: 767px) {
  .banner.auto {
    height: auto !important;
  }
}

/* label */
.label {
  display: inline-block;
  padding: 2px 5px;
  text-transform: uppercase;
  margin-right: 5px;
  font-size: 14px;
  font-weight: bold;
  color: @White;;

  // color
  &.label-blue {
    background-color: @Blue;
  }
  &.label-green {
    background-color: @Green;
  }
  // size
  &.label-lg {
    padding: 5px 16px;
  }
}


/* feature items */
.feature-items {
  text-align: center;
  margin: 60px 0;
}
.feature-items.sprite a:hover .image span {
  background-position: 0 100% !important;
}
.feature-items .image {
  display: table;
  margin: 0 0 20px;
  width: 100%;
}
.feature-items .image .inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
.feature-items .image .inner span {
  display: inline-block;
}
.feature-items .text {
  font-size: 24px !important;
  margin-top: 11px;
  margin-bottom: 7px;
  line-height: 26px !important;
  letter-spacing: -0.03em;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  color: #000000 !important;
}
ul .feature-items .text {
  line-height: 30px !important;
}
@media (max-width: 991px) {
  .feature-items .text {
    font-size: 21px !important;
    line-height: 23px !important;
  }
}
ul .feature-items .text {
  line-height: 30px !important;
}
@media (max-width: 991px) {
  .feature-items .text {
    font-size: 21px !important;
    line-height: 23px !important;
  }
}

/* separator */
.separator {
  overflow: hidden;
}
.separator.noborder {
  border-top: 0;
}
.separator.arrow.separator-alabaster > div span {
  background-color: #fafafa;
}
.separator.arrow > div {
  border-top: 1px solid #dcdcdc;
}
.separator.arrow > div span {
  display: block;
  width: 50px;
  height: 50px;
  margin: auto;
  background-color: #ffffff;
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  -ms-transform: scale(1.1, 0.6) rotate(45deg);
  -webkit-transform: scale(1.1, 0.6) rotate(45deg);
  transform: scale(1.1, 0.8) rotate(45deg);
  position: relative;
  top: -26px;
}


.pre {
  white-space: pre;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.ct-buttons {
  padding: 15px 0;
}
.selected {
  position: relative;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* visibility */
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
  visibility: hidden !important;
}
.affix {
  position: fixed;
}

.dropdown-links {
    font-size: 11px;
    color: #000;
    padding: 2px 10px;
}
.mboxDefault { visibility: hidden !important; }

.display-on-highlight {
    z-index: 5;
    position: relative;
}
.border-gray {
    border: 1px solid #D7D7D7;
}

.hyphens {
  hyphens: auto;
}

.word-break {
  word-break: break-all;
  word-break: break-word;
}
