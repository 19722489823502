@import '../variables/variables.less';

@white: #fff;
@base-unit: 1rem;
@body-sm-font-size: @base-unit * 0.75;


.sticky-bar {
  position: fixed;
  background: @white;
  padding: (@base-unit * .75) 0;
  box-shadow: 0px 20px 50px rgba(30, 34, 42, 0.1);
  transform: translateY(-220%);
  transition: all 150ms ease-out;
  top: 0;
  left: 0;
  right: 0;

  &__badge {
    display: none;
    color: #1E222A;
    @media @desktop {
      display: inline-block;
    }
    img {
      height: 32px;
    }
    span:not(.sticky-bar__badge-note) {
      display: inline-block;
      margin-left: 16px;
      font-size: 13px;
    }
  }

  &__badge-note {
    color: #1E222A;
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
    font-family: @font-family-roboto;
    &::before {
      content: '•';
      margin: 0 24px;
    }
  }

  &__note {
    display: none;
    color: #1E222A;
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
    font-family: @font-family-roboto;
    @media @desktop {
      display: inline-block;
    }
  }

  .container {
    width: auto;
    max-width: 100%;
    padding-left: (@base-unit*2.5);
    padding-right: (@base-unit*2.5);

    @media @mobile {
      padding-left: (@base-unit*1.25);
      padding-right: (@base-unit*1.25);
    }

    @media @desktop {
      padding-left: 125px;
      padding-right: 125px;
    }
  }

  &.is-sticky {
    display: block;
    z-index: 7777; // why does .bg-image-7.text-center.margin-xs-top-small.margin-xs-bottom-medium.margin-md-top-none.margin-md-bottom-none has z-index 9999?!...
    transform: translateY(0);
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__right {
    width: 100%;
    text-align: center;
    @media @tablet, @desktop {
      width: auto;
      text-align: right;
    }

    @media @mobile {
      display: flex;
      justify-content: center;
      gap: 16px;
    }

    .button {
      span {
        display: inline-block;
      }
    }
  }

  .logo-erb {
    display: none;
    @media @tablet, @desktop {
      display: block;
    }

    .lp-avg-logo {
      margin-top: 7px;
      margin-bottom: 6px;
      height: 40px;
    }
  }

  // there are various name for class on this element
  .product-awards,
  .sticky-awards {
    display: none;

    @media @desktop {
      display: flex;
      align-items: center;
    }

    span {
      margin-left: @base-unit;
      font-size: @body-sm-font-size;
    }
  }

  // there are various name for class on this element
  .dot,
  .sticky-dot {
    display: none;
    margin: 0 @base-unit*1.5 0 @base-unit*1.5;

    @media @desktop { display: block; }
  }

  // there are various name for class on this element
  .get-free,
  p.get-free,
  .sticky-text {
    @extend .h7;
    margin: 0 @base-unit 0 0;
  }


  // Maybe not required wrapper
  .buttons {
    display: flex;
    align-items: center;
  }

  .button {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 4px;
    padding: 9px 24px 9px 24px;
    font-size: 18px;
    letter-spacing: -0.03em;
    margin-bottom: 0;
    margin: 5px 12px;
    min-width: 130px;
    line-height: 1.2;

	@media @mobile {
		font-size: 14px !important;
		min-width: unset !important;
		margin: 0;
	}

    &:first-of-type {
      @media @tablet, @desktop {
        margin-left: 24px;
      }
    }
    small {
      font-size: inherit;
    }
  }

  .button-outline {
    border: 1px solid #C7CDD7;
    color: #4D5566;
    &:hover {
      background-color: #4D5566;
      border-color: #4D5566;
      color: @white;
    }
  }

  .button-flat {
    background-color: #008941;
    color: #fff;
    border: 1px solid #008941;
    transition: background-color 0.2s;
    &:hover {
      color: #fff;
      background-color: darken(#008941, 5%);
    }
  }

  .or {
    display: none;
    margin: 0 @base-unit;

    @media @desktop { display: inline-block; }
  }

  @media @mobile {
    .btn:not(.btn-stay) {display: none;}

    // Keep the button primary, no matter what.
    .btn-stay {
      @include btn-spring;
      @include btn-shadow('spring');
    }
  }
}
