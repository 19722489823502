/* Cookie bar */

.cookie-bar {
    background: #4b4b4b;
    font-size: 14px !important;
    line-height: 22px !important;
    margin: 0;
    padding-top: 16px;
    padding-bottom: 16px;

    .row > [class*="span"] {
      display: flex;
      justify-content: start;
      align-items: center;
    }
 
    p {
      color: #ffffff;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 0;
    }
    
}