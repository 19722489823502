@import '../variables/variables.less';

.notification-overlay-for-wrong-download {
	align-items: center;
	background-color: rgba(0, 0, 0, .8);
	bottom: 0;
	display: none;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 9000;

	@media @mobile {
		overflow-y: scroll;
	}

	&.show-popup {
		display: flex;

		@media @mobile {
			display: block;
		}
	}

	.detected-pc,
	.detected-android,
	.detected-mac,
	.detected-ios,
	.install-pc,
	.install-android,
	.install-mac,
	.install-ios {
		display: none;
	}

	&.no-alternative-text {
		.alternative-text {
			display: none !important;
		}
	}

	&.alternative-text {
		.sub,
		.instead-no-alt {
			display: none !important;
		}
	}

	&.alternative-text-b {
		.alternative-text-c {
			display: none !important;
		}
	}

	&.alternative-text-c {
		.alternative-text-b {
			display: none !important;
		}
	}

	&.detected-others {
		.instead {
			display: none !important;
		}
	}

/* -------------------------------------------------------  */

	&.detected-windows.install-mac {
		.detected-pc.install-mac {display: inline;}
	}

	&.detected-windows.install-android {
		.detected-pc.install-android {display: inline;}
	}

	&.detected-windows.install-ios {
		.detected-pc.install-ios {display: inline;}
	}

/* -------------------------------------------------------  */

	&.detected-mac.install-windows {
		.detected-mac.install-pc {display: inline;}
	}

	&.detected-mac.install-android {
		.detected-mac.install-android {display: inline;}
	}

	&.detected-mac.install-ios {
		.detected-mac.install-ios {display: inline;}
	}

/* -------------------------------------------------------  */

	&.detected-android.install-windows {
		.detected-android.install-pc {display: inline;}
	}

	&.detected-android.install-mac {
		.detected-android.install-mac {display: inline;}
	}

	&.detected-android.install-ios {
		.detected-android.install-ios {display: inline;}
	}

/* -------------------------------------------------------  */

	&.detected-ios.install-windows {
		.detected-ios.install-pc {display: inline;}
	}

	&.detected-ios.install-mac {
		.detected-ios.install-mac {display: inline;}
	}

	&.detected-ios.install-android {
		.detected-ios.install-android {display: inline;}
	}

/* -------------------------------------------------------  */

	&.detected-unknown {
		&.install-windows {
			.detected-unknown.install-pc {
				display: inline;
			}
		}

		&.install-mac {
			.detected-unknown.install-mac {
				display: inline;
			}
		}

		&.install-android {
			.detected-unknown.install-android {
				display: inline;
			}
		}

		&.install-ios {
			.detected-unknown.install-ios {
				display: inline;
			}
		}
	}

	&.detected-others,
	&.detected-unknown {
		.back {
			display: inline;
		}
	}

/* -------------------------------------------------------  */
	&.detected-windows {
		.platform-icon.detected-pc {display: inline;}
	}

	&.detected-mac {
		.platform-icon.detected-mac {display: inline;}
	}

	&.detected-android {
		.platform-icon.detected-android {display: inline;}
	}

	&.detected-ios {
		.platform-icon.detected-ios {display: inline;}
	}
/* -------------------------------------------------------  */

	.close-popup {
		background: url('../i/ico/close-icon.png') no-repeat 50% 50%;
		background-size: 100%;
		cursor: pointer;
		display: block;
		height: 18px;
		position: absolute;
		right: 35px;
		width: 18px;
		top: 33px;

		@media @mobile {
			right: 29px;
			top: 29px;
		}
	}

	.platform-icon {
		margin-bottom: 1rem;
		max-height: 45px;

		@media @mobile {
			margin-top: 5px;
		}
	}

	.content {
		align-items: center;
		background-color: #ffffff;
		color: #000000;
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 550px;
		padding: 46px 22px;
		position: relative;
	}

	.title {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 24px;
		text-align: center;

		@media @mobile {
			max-width: 190px;
		}
	}

	.main {
		display: block;
		font-weight: 700;
		font-size: 20px;
		padding-bottom: 5px;
	}

	.button.button-large {
		font-size: 20px !important;
		line-height: 20px;
		margin-bottom: 25px;

		@media @mobile {
			line-height: 17px !important;
			padding: 11px 15px 11px 15px !important;
		}
	}

	.from-store {
		color: #5c707b;
		font-size: 14px;
		margin-bottom: 16px;
		margin-top: -20px;
		text-align: center;
	}

	.instead {
		font-size: 16px;
		margin-bottom: 11px;
		text-align: center;
	}

	.back {
		display: none;
		font-size: 16px;
	}

	.custom-font {
		font-weight: bold;
	}
}