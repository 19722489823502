@import '../../variables/variables.less';


/*
Carousel Component

NOTE:
Width of .tiny-slider is calculated from sum of active items and their margins.
This sizes must be aligned with item sizes in settings (in js file).
*/
@base-unit: 16px;

.carousel-slider {
	overflow-x: hidden;

	a {
		.button {
			&.secondary {
				span {
					border-bottom: 2px dashed @Blue-v2;
				}
			}
		}
	}

	&.overflow-visible {
		.tns-ovh { overflow: visible; }
	}

	.tiny-slider {
		position: relative;
	}

	.tns-slider { display: flex; }

	.blog-perex {
		-webkit-line-clamp: 3;
		text-overflow: ellipsis;
		max-height: 72px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}

	.blog-title {
		-webkit-line-clamp: 2;
		max-height: 61px;
		overflow: hidden;
		text-overflow: ellipsis;
    	display: -webkit-box;
    	-webkit-box-orient: vertical;
		@media @mobile {
			max-height: 48px;
		}
	}


	// Buttons / Controls
	//------------------------
	.slider-controls {

		&.buttons-disabled {
			button {
				display: none;
			  }
		}

		button {
			position: absolute;
			z-index: 10;
			display: none;

			padding: @base-unit 0 0;
			background: @GreenPrimary-v2;
			border: 1px solid @GreenPrimary-v2;
			border-radius: 4px;
			width: 56px;
			height: 56px;
			line-height: 56px;

			&:disabled {
				background: @White;
				border-color: @Grey-8;
				opacity: 1;

				img {
					filter: invert(72%) sepia(8%) saturate(1267%) hue-rotate(182deg) brightness(94%) contrast(96%);
				}
			}

			img { width: auto !important; }

			.btn-icon-normal,
			.btn-icon-hover {
				margin: 0 auto;
			}
		}

		@media (min-width: 1280px) {
			button { display: block; }

			.prev {
				right: 88px;
				top: 88px;
			}
			.next {
				right: 0;
				top: 88px;
			}
		}
		@media (min-width: 1400px) {
			.prev {
				right: -48px;
			}
			.next {
				right: -136px;
			}
		}

	}

	&.infinity,
	&.single {
		@media (min-width: 1280px) {
			button {
				display: block;
				top: calc(50% - 24px) !important;
			}

			.prev { left: (-@base-unit * 6); }

			.next { right: (-@base-unit * 6); }
		}
	}


	// Dots / Navigation
	//------------------------
	.tns-nav {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		padding: 0 (@base-unit / 2);
		bottom: (-@base-unit * 3);
		left: 0;
		right: 0;
		z-index: 9;

		button {
			height: (@base-unit * .5);
			width: (@base-unit * .5);
			min-width: (@base-unit * .5);
			border-radius: 50%;
			padding: 0;
			border: 0;
			background-color: @Grey-7;

			&.tns-nav-active {
				height: (@base-unit * .75);
				width: (@base-unit * .75);
				min-width: (@base-unit * .75);
				background-color: @GreenPrimary-v2;
			}

			&:not(:first-of-type) {
				margin-left: (@base-unit * 2);

				&:focus {
					outline: none;
				}
			}
		}
	}


	// Carousel tiny slider styling
	//-----------------------------
	.tiny-slider {
		.tns-item {
			transition-duration: .4s;
			transition-property: all;
			transition-timing-function: ease;

			&:hover {
				h4 {
					color: @Blue;
				}
			}
		}

		.tns-inner {
			position: relative;
			&:before {
				display: none;
				position: absolute;
				content: '';
				width: 2000px;
				height: 107%;
				left: -2000px;
				background-image: linear-gradient(to left, rgba(255, 255, 255, .01) 0%, #fff 256px);
				z-index: 3;
				top: -7%;

				@media (min-width: 1280px) {
					display: block;
				}
			}

			&:after {
				display: none;
				position: absolute;
				content: '';
				width: 2000px;
				height: 107%;
				right: -2000px;
				z-index: 3;
				top: -7%;
				background-image: linear-gradient(to right, rgba(255, 255, 255, .01) 0%, #FFFFFF 256px);

				@media (min-width: 1280px) {
					display: block;
				}
				@media (min-width: 1325px) {
					right: -1936px;
				}
			}
		}
	}

	&.gradient-disabled {
		.tns-inner {
			&:after {
				background-image: none;
			}
		}
	}

	&.dark {

		h4, p {
			color: @White;
		}

		.slider-controls {
			button {
			  &.prev {
				  background: @Black-v2;
				  border-color: @White;
			  }
			  &.next {
				  background: @Blue-v2;
				  border: none;
			  }
			}
		  }

		  .tiny-slider {
			.tns-inner {
				&:before {
				  background-image: linear-gradient(90deg, rgba(30, 34, 42, 0) 0%, #1E222A 100%);
				}
				&:after {
					background-image: linear-gradient(to right, rgba(30, 34, 42, 0) 0, #1E222A 260px);
					@media (min-width: 1325px) {
						right: -1750px;
					}
				}
			  }
		  }

		  .tns-nav {
			button {
				&:not(:first-of-type) {
					&.tns-nav-active {
						background-color: @White;
					}
				}
			}
		  }
	}
}
