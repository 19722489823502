@import '../variables/variables.less';

/* Social Connect component */

// icons
.social-connect {
    .icon-tw { background: url("../i/other/social-share-icons/footer-social-share-twitter-v2.svg") 0 0 no-repeat; }
    .icon-fb { background: url("../i/other/social-share-icons/footer-social-share-facebook-v2.svg") 0 0 no-repeat; }
    .icon-yt { background: url("../i/other/social-share-icons/footer-social-share-youtube-v2.svg") 0 0 no-repeat; }
    .icon-linkedin { background: url("../i/other/social-share-icons/footer-social-share-twitter-linkedin.svg") 0 0 no-repeat; }
}

// Used in footer

.social-connect {
    @media @tablet, @desktop {
        padding: 0 32px 0 32px;
        margin-bottom: 50px;
        border-left: 1px solid @FooterBorder-v2;
        flex: 0 0 25%;
    }

    // this element has different mobile behaviour than other columns!
    @media @mobile {
        padding: 40px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid @FooterBorder;
    }

    h4 {
        @media @mobile {
            display: none;
        }
    }

    ul {
        @media @mobile {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
        }

        li {
            @media @tablet, @desktop {
                margin-bottom: 5px;
            }

            @media @mobile {
                display: inline-block;
                margin: 0;
                padding-bottom: 0;
            }

            a {
                display: inline-block;
                line-height: 28px;
                font-size: 14px;

                @media @mobile {
                    // hiding text
                    overflow: hidden;
                    height: 28px;
                }
                .icon {
                    width: 28px;
                    height: 28px;

                    @media @tablet, @desktop {
                        display: inline-block;
                        margin-right: 15px;
                        vertical-align: middle;
                    }
                    @media @mobile {
                        display: block;
                        margin: auto;
                    }
                }
            }
        }
    }
}
