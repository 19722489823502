@import '../variables/variables.less';

/* # MessageLine */
@messageLinePrimaryBgColor: @Grey-1;
@messageLineSecondaryBgColor: @GreenPrimary;


.message-bar {
	background: @messageLinePrimaryBgColor;
    padding: 0 5%;

	@media @mobile {
		padding-top: 16px;
		padding-bottom: 16px;
	}

    a {
        text-decoration: none;
        color: @White;
    }
	&__content {
		min-height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 22px;
		font-weight: normal;
		color: @White;
        text-decoration: none;
        letter-spacing: -0.5px;
		@media @tablet {
			font-size: 16px;
			min-height: 65px;
		}
		@media @mobile{
			font-size: 16px;
			min-height: 34px;
		}
		svg, img {
flex-shrink: 0;

			&.left {
				transition: all 300ms;

				@media @mobile{
					margin-right: 9px;
					transform: translateX(-50%);
					animation: 1s ease-in-out 1s envelopeMoveR_mobile forwards;
				}
				@media @tablet, @desktop {
					margin-right: 17px;
					transform: translateX(-100%);
					animation: 1s ease-in-out 1s envelopeMoveR forwards;
				}
			}

			&.right {
				transition: all 300ms;

				@media @mobile{
					margin-left: 9px;
					transform: translateX(50%);
					animation: 1s ease-in-out 1s envelopeMoveL_mobile forwards;
				}
				@media @tablet, @desktop {
					margin-left: 17px;
					transform: translateX(100%);
					animation: 1s ease-in-out 1s envelopeMoveL forwards;
				}
			}

			circle {
				opacity: 0;
				animation: 0.5s ease-in-out 2s envelopeDot forwards;
			}
		}

		span {
			padding: 1px 0;
			text-decoration: underline;
			text-underline-offset: 6px;
			@media @mobile {
				text-underline-offset: 1px;
			}
		}
		&:hover {
			text-decoration: none;
			span {
				text-decoration: none;
                color: @White;
				@media @mobile {
					text-decoration: none;
				}
			}
		}

		&.no-link {
			span {
				box-shadow: none;
				text-decoration: none;
			}
		}
	}


	&.message-bar--secondary {
		background: @messageLineSecondaryBgColor;
	}

	&.green-rebranding {
		background: @GreenRebranding;
	}

	&.red-alert {
		background: @Red-v2;
	}

	&.blue {
		background: @Blue-v2;
	}

	&.red {
		background-color: #D93511;
	}

	&.orange {
		background: #FFB600;
		a, span {
			color: @Black-v2;
		}
	}

	&.with-logo {
		background-color: #1C222E;

		.message-bar__content {
			@media @tablet, @mobile {
				justify-content: start;
				gap: 2rem;
			}

			.bar-image {
				margin-top: 20px;
				height: 36px;
				padding-left: 40px;
				position: absolute;
				top: 0;
				left: 0;

				@media @desktop {
					margin-top: 20px;
				}
				@media @tablet, @mobile {
					max-width: none;
					height: 36px;
					position: relative;
					padding-left: 0;
					margin-top: 0;
				}
			}

			.left {
				transform: none;
				transition: none;
				animation: none;
				@media @tablet, @mobile {
					display: none;
				}
			}
		}
	}
}

/* GLOWEB-5459 */
#ru-message-bar {
	display: none;

	&.message-bar {

		@media @mobile {
			padding-top: 5%;
			padding-bottom: 5%;

			.message-bar__content {
				height: auto;
			}

			svg.left {
				display: none;
			}
		}

		.button.alert-btn {
			border: 1px solid #292E38;
			margin-left: 24px;
			margin-bottom: 0;
			span.btn {
				color: @Grey-1;
				background-color: @White;
				border-radius: 5px;
				padding: 6px 24px;
				font-size: 16px;
				height: 48px;
				line-height: 1;
				background: @White;
				font-weight: 700;
				display: flex;
				justify-content: center;
				align-items: center;

				&:hover {
					color: @Grey-1;
				}
			}
		}
	}
}
/* /GLOWEB-5459*/

/* Fix for current absolute navigation */
.navigation-for-mobile {
	padding-top: 120px !important;

	.message-bar {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
	.navigation-oo-19798 {
		top: 56px;
	}
}

@keyframes envelopeMoveR {
	0% { transform: translateX(-100%); }
	100% { transform: translateX(0); }
}

@keyframes envelopeMoveL {
	0% { transform: translateX(100%); }
	100% { transform: translateX(0); }
}

// Animation for mobile
@keyframes envelopeMoveR_mobile {
	0% { transform: translateX(-50%); }
	100% { transform: translateX(0); }
}
@keyframes envelopeMoveL_mobile {
	0% { transform: translateX(50%); }
	100% { transform: translateX(0); }
}


@keyframes envelopeDot {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.counter-wrapper {
	min-height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}

.counter-wrapper {
	gap: 54px;

	@media @mobile {
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
	}
}

.countdown, .cmp-countdown {
	display: flex;

	.time-block,
	.colon {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.time-block {
		width: 40px;
	}

	.colon {
		width: 7px;
	}

	.numbers {
		font-size: 24px;
		line-height: 24px;
		font-weight: 700;

		@media @mobile {
			font-size: 18px;
			line-height: 18px;
		}
	}

	.caption {
		font-size: 12px;
		line-height: 12px;
	}
}

.counter-bg-blue {
	gap: 24px;

	@media @mobile {
		gap: 12px;
	}

	.cmp-countdown {
		padding: 8px 16px;
		background: @Blue-v2;
		color: @White;
	}
}

.d-none {
	display: none;
}


//
// Message bar for India (EN-IN) - no VPN in this locale
// GLOWEB-1935 (Corp. Jira)
// DS3 design
//
#message-bar_en-in {
	font-family: 'Roboto';	// fix for obsolete pages
	padding: 14px 20px;

	&.red-alert {
		background-color: #DB3559;
	}

	.message-bar__content {
		max-width: 1320px;
		margin-left: auto;
		margin-right: auto;	
		min-height: auto;
		gap: 16px;

		font-weight: 700;

		@media @desktop {
			gap: 24px;
			font-size: 20px;
			line-height: 26px;
		}
	}
}