@import "../variables/variables.less";
@import "../mixins/mixins.less";


/* Blogposts*/
#blogposts {

    img {
        margin-bottom: 32px;
    }
    h4 {
        margin-bottom: 24px;
    }

    // all articles link
    .link-all {
        text-align: center;
        margin-bottom: 50px;

        a {
        color: @Blue;
        border-bottom: 1px dotted @Blue;
        padding-bottom: 2px;
    
        &:hover,
        &:active,
        &:visited {
          text-decoration: none;
            }
        }
    }

    .link-post {
        color: @Blue;
        border-bottom: 1px dotted @Blue;
        padding-bottom: 2px;
        margin-bottom: 24px;
    
        &:hover,
        &:active,
        &:visited {
          text-decoration: none;
        }
    }

    // posts
    .posts {
        @media @mobile {
            margin-bottom: 65px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
} 