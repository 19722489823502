@import '../../variables/variables.less';

@base-unit: 16px;

.combined-actionbox {
	display: flex;
	justify-content: center;
	width: 100%;
	max-width: 1052px;
	margin: 0 auto;

    @media @mobile, @tablet {
        padding: 0 0 @base-unit; // bottom padding must be set by actionbox margin
		flex-direction: column;
		align-items: center;
		max-width: 348px;
	}

	/* List aside containers
	==============
	*/
	.actionbox-list-container, // obsolete!
	.actionboxes-list {
		padding: 4rem 2rem;
		max-width: 360px;
		background: #F0F2F4;
		text-align: initial;
        @media @mobile {
			width: 100%;
			padding: 2rem 1.25rem;
		}
	}

	.actionboxes-list {
		width: 100%;
		.btn-wrapper {
			margin-top: 20px;
            @media @mobile {
                margin-top: 4px;
            }

			.btn {
				height: auto;
				text-transform: none;
                border-bottom: 2px dashed @Blue-v2;
                color: @Blue-v2;
                font-weight: 500;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
			}
		}

        .content {
            .text {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                font-size: 14px;
                font-weight: 400;
                color: @Grey-1;
                text-align: left;
                margin-bottom: 8px;
                @media @mobile {
                    margin-bottom: 24px;
                    font-size: 12px;
                }

                img {
                    margin-right: 10px;
                    margin-top: 3px;
                }
            }

            .title {
                color: @Black-v2;
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 16px;
                @media @mobile {
                    font-size: 14px;
                }
            }
        }
	}


	/* Actionboxes
	==============
	*/
	.actionboxes-wrapper {
		display: flex;
		flex-direction: column;


        @media @mobile, @tablet {
			//padding-top: @base-unit * 2.5;
			width: 100%;

		}

		.actionboxes-boxes {
			display: flex;
			height: 100%;

            &.actionbox {
                margin-bottom: 0;
				@media @mobile, @tablet {
					width: 100%;
				}
            }

			 @media @mobile, @tablet {
				flex-direction: column;
				width: 100%;
			}
		}

		/* two pricingboxes side by side */
		&.combo {
			.actionbox-container {
				width: 300px;

                @media @mobile, @tablet {
					width: auto;
				}
			}

			.try-box-wrap {
				padding: 0;

                @media @mobile, @tablet {
					margin-bottom: 0;
				}

				.try-box .try-box-container {
					width: 300px;

                    @media @tablet {
						width: 250px;
					}
                    @media @mobile {
						width: auto;
					}
				}
			}

			.actionboxes-boxes > div:first-of-type {
				&:after {
					content: '';
					width: 1px;
					height: 80%;
					position: absolute;
					top: 10%;
					right: 0;
					background-color: @Grey-12;

                    @media @mobile, @tablet {
						display: none;
					}
				}

				:lang(ar) &,
				:lang(he) & {
					&:after { display: none; }

					&:before {
						content: '';
						width: 1px;
						height: 80%;
						position: absolute;
						top: 10%;
						left: 0;
						border-right: 1px solid @Grey-10;
						z-index: 1;

                        @media @mobile {
							border-right: none;
						}
					}

				}
			}
		}
	}

	.vue-action-box {
		margin: 0;
		position: relative;
		min-width: 300px;

        @media @mobile {
			height: auto; // test
		}

		.actionbox-container {
			padding: @base-unit * 2 @base-unit;
			box-shadow: none;
			border: none;

            @media @mobile {
				min-width: 100%;
				height: auto; // test
				// margin-bottom: @base-unit; // sets the spacing in case of multiple actionboxes
			}
		}

		.box {
			.badge {
				+ div .badge-placeholder {
					display: none;
				}
			}

			&.promotion {
				margin: 0;
			}
		}
	}

	.try-box-wrap {
		position: relative;
		padding: 0 @base-unit;

		.try-box {
			margin: 0;

			.try-box-container {
				box-shadow: none;
				border: none;
				height: auto;
			}
		}
	}

	.actionboxes-footer {
		position: relative;
		padding: 0 @base-unit;
		background-color: @White;

		@media @tablet, @mobile {
			display: none;
		}

		&.horizontal {
			.box {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: @base-unit 0;
				margin: 0;
				//height: 52px;
				border-top: 1px solid @Grey-12;

				.money-back__item {
					padding: 0;
					margin: 0;
				}
			}

			.btn-wrapper {
				margin-right: 10px;
			}
		}

		.money-back {
			@media @desktop {
				padding: 15px 0;
			}

			&.money-back--small {
				span {
					font-size: @base-unit;
				}
			}
		}
	}
}
