/* global */
.navigation {
	ul,
	ol {
		li {
			padding-bottom: 0;
		}
	}

    .container {
        padding-right: 0;
        padding-left: 20px;
		// do no remvove - fix for transparent header
		@media (min-width: 992px) {
			&::after {
				content: " ";
				display: block;
				clear: both;
			}
			.clearfix {
				clear: both;
			}
		}

		@media (min-width: 1056px) {
			padding-left: 56px;
		}
    }
}

/* floating */
// will be removed after rewriting header styles
.pull-center {
	margin: 0 auto;
}
.pull-right {
	float: right !important;
}
.pull-left {
	float: left !important;
}
.pull-xs-left {
	float: left !important;
}
@media (min-width: 480px) {
	.pull-sm-left {
		float: left !important;
	}
}
@media (min-width: 768px) {
	.pull-md-left {
		float: left !important;
	}
}
@media (min-width: 992px) {
	.pull-lg-left {
		float: left !important;
	}
}
.pull-xs-right {
	float: right !important;
}
@media (min-width: 480px) {
	.pull-sm-right {
		float: right !important;
	}
}
@media (min-width: 768px) {
	.pull-md-right {
		float: right !important;
	}
}
@media (min-width: 992px) {
	.pull-lg-right {
		float: right !important;
	}
}

/* navigation */
.navigation .promo,
.navigation .label-blue,
.navigation .label-orange,
.navigation .label-green,
.navigation .label-red {
	padding: 0 4px;
	margin: 0 0 0 5px;
}

.navigation li span.label-new {
	background-color: #D9E8FA;
	font-size: 14px;
    text-transform: uppercase;
    line-height: 20px;
    color: #1B61B5;
    font-weight: 700;
	border-radius: 12px;
	padding: 2px 16px;
	margin: 0 16px;
}

/* from bootstrap */
@media (min-width: 992px) {
	#body-inner,
	.navigation {
		position: static !important;
	}
}
@media (max-width: 991px) {
	.nav-active {
		overflow: hidden !important;
	}
	.nav-active.br-msie.br-ver-8 {
		position: static;
		overflow-y: auto !important;
		left: auto;
		right: auto;
	}
	.nav-active.br-msie.br-ver-8 .naigation-row,
	.nav-active.br-msie.br-ver-9 .naigation-row {
		right: -600px !important;
	}
	.nav-active.br-msie.br-ver-8 .naigation-row {
		overflow-y: auto !important;
	}
	.android-2.nav-active,
	.android-3.nav-active {
		position: static !important;
		overflow-y: auto !important;
	}
	.android-2.nav-active .navigation-row,
	.android-3.nav-active .navigation-row {
		position: absolute !important;
		overflow-y: auto !important;
		right: -300px !important;
		height: auto !important;
	}
}
.navigation.global-navigation {
	min-height: 38px;
	border-bottom: 1px solid #e2e2e2;
	padding: 22px 0 18px;

	&.absolute {
		position: absolute !important;
		top: 0;
		left: 0;
		z-index: 5;

		&.global-navigation {
			border-bottom: none;
		}
	}

	&.transparent {
		background-color: transparent;
	}
}

.navigation .userLink {
	color: #249efc !important;
}
.navigation .title {
	margin-right: 10px;
	margin-top: 1px;
	text-transform: uppercase;
}

/* navigation top / header */
.navigation .navigation-top {
	margin-right: 15px;

	&::after {
		content: '';
		display: block;
		clear: both;
	}
}
.navigation .navigation-top a {
	float: left;
	font-size: 12px;
	color: #6D768D;
	cursor: pointer;
    &.icon-href {
        background-color: #F8F9FA;
        border-radius: 20px;
        padding: 6px 12px 0 6px;
        text-decoration: none;
        margin-top: -8px;

		.icon-flag {
			margin-right: 0.5rem;
		}

        img {
            position: relative;
            top: -8px;
        }
    }
}
.navigation .navigation-top a.loginLink,
.navigation .navigation-top a.logoutLink,
.navigation .navigation-top a.userLink {
	padding-right: 24px;
	margin-right: 24px;
	border-right: 1px solid #C7CDD7;
	letter-spacing: 0;
    color: #6D768D;
}
.navigation .navigation-buttons {
	padding: 30px 30px 20px;
}
.navigation .navigation-buttons a {
	padding: 8px 10px !important;
	margin-bottom: 10px !important;
	width: 100%;
	font-size: 14px;
}
.navigation .navigation-buttons a.logout {
	margin-top: 10px;
}
.navigation .navigation-buttons a > span {
	text-align: center;
	position: relative;
}
.navigation .navigation-buttons a .icon-flag {
	display: inline-block;
	position: absolute;
	margin-left: -16px;
	margin-top: -4px;
}
.navigation .navigation-buttons a .icon-flag + span {
	position: relative;
	left: 16px;
}
@media (max-width: 991px) {
	.navigation .navigation-buttons a .icon-flag {
		position: relative;
		margin-left: 0;
		margin-top: 0;
	}
	.navigation .navigation-buttons a .icon-flag + span {
		left: 0;
		margin-right: 6px;
	}
}
@media (min-width: 992px) {
	.navigation .navigation-header {
		float: left;
	}
	.navigation .navigation-right {
		float: right;
	}
	.navigation .navigation-left {
		float: left;
	}
}
.navigation .navbar-toggle:hover,
.navigation .navbar-toggle:focus {
	background-color: #e1e1e1;
}
.navigation .navbar-toggle .icon-bar {
	background-color: #5c707b;
}
@media (min-width: 992px) {
	.navigation .navigation-collapse {
		width: auto;
		border-top: 0;
		box-shadow: none;
	}
	.navigation .navigation-collapse.collapse {
		display: block !important;
		visibility: visible !important;
		height: auto !important;
		padding-bottom: 0;
		overflow: visible !important;
	}
}
@media (min-width: 992px) {
	.navigation .navigation-wrapper {
		margin: 14px 0 0;
		position: relative;
	}
	.navigation .navigation-nav {
		list-style-type: none;
		margin: 0;
		padding: 0;
		z-index: 1000;
		position: relative;

        .support-item {
            img {
                margin-left: 8px;
                margin-top: -1px;
            }
        }
	}
	.navigation .navigation-nav ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
	.navigation .navigation-nav li a {
		color: #1E222A;
	}
	.navigation .navigation-nav li a:hover,
	.navigation .navigation-nav li a:focus {
		text-decoration: none;
	}
	.navigation .navigation-nav > li {
		position: relative;
		float: left;
		margin: 0 4px;
	}
	.navigation .navigation-nav > li.selected > a,
	.navigation .navigation-nav > li.active > a {
		border-bottom: 5px solid #399e2d;
		padding-bottom: 1px;
	}

	.navigation .navigation-nav > li:hover > a:not(.cart-link),
	.navigation .navigation-nav > li:focus > a:not(.cart-link) {
		color: #ffffff;
		background-color: #399e2d;
	}

	.navigation
		.navigation-nav
		> .mobile-android-link.open:hover
		> a:not(.cart-link)
		+ ul,
	.navigation
		.navigation-nav
		> .mobile-android-link.open:focus
		> a:not(.cart-link)
		+ ul,
	.navigation .navigation-nav > li:hover > a:not(.cart-link) + ul,
	.navigation .navigation-nav > li:focus > a:not(.cart-link) + ul,
	.navigation .navigation-nav > li.expanded > a:not(.cart-link) + ul {
		display: block !important;
	}

	.navigation
		.navigation-nav
		> .mobile-android-link:hover
		> a:not(.cart-link)
		+ ul {
		display: none !important;
	}

	.navigation .navigation-nav > li > a {
		padding: 6px 16px;
		font-size: 16px;
		display: inline-block;
        color: #1E222A;
	}
	.navigation .navigation-nav > li > a:hover:not(.cart-link),
	.navigation .navigation-nav > li > a:focus:not(.cart-link),
	.navigation .navigation-nav > li.expanded > a:not(.cart-link) {
		color: #ffffff;
		background-color: #399e2d;
	}
	.navigation .navigation-nav > li > a + ul {
		display: none !important;
		position: absolute;
		width: 310px;
		z-index: 1000;
		background: #ffffff;
		border-top: 5px solid #399e2d !important;
		right: 0;
		box-shadow: 0 3px 5px 0 rgba(3, 3, 3, 0.2);
		-webkit-box-shadow: 0 3px 5px 0 rgba(3, 3, 3, 0.2);
	}
	.navigation .navigation-nav > li > a + ul li:first-child {
		border-top: 0 !important;
	}
	.navigation .navigation-nav > li > a + ul li.nav-title {
		font: bold 14px/20px 'Roboto', Arial, Helvetica, sans-serif;
		color: #000;
		border-top: 5px solid #399e2d;
		background: 0;
		cursor: default;
	}
	.navigation .navigation-nav > li > a + ul li.nav-title.nav-title-nolink {
		padding: 11px 15px;
	}
	.navigation .navigation-nav > li > a + ul li.nav-title a {
		font: bold 14px/20px 'Roboto', Arial, Helvetica, sans-serif;
	}
	.navigation .navigation-nav > li > a + ul li > a {
		border-top: 1px solid #ececec;
		padding: 10px 15px 11px;
		color: #000;
		font: normal 13px/16px 'Roboto', Arial, Helvetica, sans-serif;
		text-decoration: none;
		display: block;
		cursor: pointer;
	}
	.navigation .navigation-nav > li > a + ul li > a:hover,
	.navigation .navigation-nav > li > a + ul li > a:focus {
		padding: 10px 15px 10px;
		background: rgba(0, 0, 0, 0.1);
		border-bottom: 1px solid #d3d3d3;
		border-top: 1px solid #d3d3d3;
	}
	.navigation .navigation-nav > li > a + ul li.divider {
		border-bottom: 1px solid #e2e2e2;
		padding: 0;
	}
}
@media (max-width: 991px) {
	.navigation {
		position: relative;
	}
	.navigation .navigation-close.button {
		padding: 10px;
	}
	.navigation .navigation-close.button:hover {
		background: #e2e2e2;
	}
	.navigation .navigation-row {
		position: fixed;
		overflow-y: scroll;
		height: 100vh !important;
		top: 0;
		right: -300px;
		background: #fafafa;
		z-index: 1000;
		width: 300px;
	}
	.br-msie .navigation .navigation-row {
		position: absolute;
	}
	.navigation .navigation-row ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	.navigation .navigation-row ul a {
		padding: 10px 30px;
		border-bottom: 1px solid #e2e2e2;
		display: block;
		color: #000000;
	}
	.navigation .navigation-row ul a:hover,
	.navigation .navigation-row ul a:focus,
	.navigation .navigation-row ul a:active {
		text-decoration: none;
	}
	.navigation .navigation-row ul ul {
		background: #707d84;
	}
	.navigation .navigation-row ul ul .nav-title.nav-title-nolink {
		padding: 10px 30px;
	}
	.navigation .navigation-row ul ul .nav-title.nav-title-nolink,
	.navigation .navigation-row ul ul a {
		padding-top: 12px;
		padding-bottom: 12px;
		font-size: 12px;
		color: #ffffff;
		border-bottom: 1px solid #ffffff;
	}
}
@media (max-width: 991px) {
	#body-inner {
		position: relative;
	}
	.navigation-header {
		position: relative;
	}
}
.navbar-toggle {
	position: relative;
	float: right;
	margin-right: 10px;
	padding: 9px 10px;
	margin-top: 2px;
	margin-bottom: 2px;
	background-color: transparent;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
}
.navbar-toggle:focus {
	outline: 0;
}
.navbar-toggle .icon-bar {
	display: block;
	width: 22px;
	height: 4px;
	border-radius: 1px;
}
.navbar-toggle .icon-bar + .icon-bar {
	margin-top: 4px;
}
@media (min-width: 992px) {
	.navbar-toggle {
		display: none;
	}
}
.navigation .close-wrapper {
	padding: 20px 30px;
}
.navigation .close-wrapper .button.navigation-close {
	width: 100%;
}

/* from component */
@bNavigation_navHeaderHeight: 50px;
@bNavigation_menuItemsMargin: 25px;
@bNavigation_green: #399e2d;
@bNavigation_gray: #e2e2e2;

@media only screen and (min-width: 992px) {
	.navigation {
		.navigation-back,
		.navigation-mobile-header-item {
			display: none;
		}

		.nav-title.nav-title-nolink {
			&:nth-child(2) {
				border: none;
			}
		}

		.last.button.button-nevada.button-large.button-outlined {
			border: none !important;
			border-top: 1px solid @bNavigation_gray !important;
			color: #000000;
			margin-bottom: 0;
			padding: 10px 15px 11px;
		}
	}
}

@media only screen and (max-width: 991px) {
	// .navigation .navigation-row ul ul {
	// 	background: white;
	// }

	.nav-active {
		.navigation {
			.navigation-row {
				overflow-x: hidden;
				right: -350px;
			}
		}
	}

	.navigation {
		.navigation-row {
			right: -350px;
			width: 350px;
		}

		.navigation-mobile-header-item {
			display: list-item;
		}
		// Navigation header with Back & Close buttons
		.close-wrapper {
			background: @bNavigation_gray;
			height: @bNavigation_navHeaderHeight;
			padding: 0 10px;

			.label {
				color: @bNavigation_green;
				display: inline-block;
				font-size: 36px;
				font-weight: bold;
				left: 50%;
				position: relative;
				text-transform: capitalize;
				transform: translateX(-50%);
			}
		}

		// Back & Close buttons
		.navigation-back,
		.navigation-close {
			cursor: pointer;
			height: 30px;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 10px;
			width: 30px;
		}

		// Close button
		.navigation-close {
			cursor: pointer;
			display: block;
			right: 16px;
			border-left: 1px solid #C7CDD7;

			// &:before,
			// &:after {
			// 	border-bottom: 3px solid #000000;
			// 	content: '';
			// 	display: block;
			// 	height: 15px;
			// 	position: absolute;
			// 	top: 3px;
			// 	width: 15px
			// }

			// &:before {
			// 	left: 1px;
			// 	transform: rotate(-45deg);
			// }

			// &:after {
			// 	left: 10px;
			// 	transform: rotate(45deg);
			// }
		}

		// Back button
		.navigation-back {
			display: block;
			left: 10px;
			transform: rotate(-90deg);

			&:before,
			&:after {
				border-bottom: 3px solid #000000;
				content: '';
				display: block;
				height: 17px;
				position: absolute;
				top: 3px;
				width: 10px;
			}

			&:before {
				left: -1px;
				transform: rotate(-45deg);
			}

			&:after {
				left: 16px;
				transform: rotate(45deg);
			}
		}

		.navigation-nav {
			.bi-nav-items.visible-lg-block {
				box-shadow: -2px 6px 5px 0px rgba(0, 0, 0, 0.32);
				height: calc(100vh + 150px);
				position: absolute;
				top: 0;
				transform: translateX(400px);
				transition: transform 0.5s linear;
				width: 340px;
				will-change: transform;
				z-index: 2000;

				&.active {
					transform: translateX(10px);
				}

				.navigation-close {
					padding: 8px 0px 8px 20px;
				}

				a {
					border-bottom: 1px solid @bNavigation_gray;
					margin: 0 @bNavigation_menuItemsMargin;

					&.last {
						margin-top: 50px;
						min-width: auto;
					}
				}

				li:nth-child(2) {
					a {
						border-top: 2px solid @bNavigation_green;
					}
				}
			}
		}

		// Submenu titles
		.navigation-row ul ul {
			background: #ffffff;

			.nav-title.nav-title-nolink {
				border-bottom: 1px solid @bNavigation_gray;
				border-top: 2px solid @bNavigation_green;
				color: #000000;
				font-weight: bold;
				margin: 0 @bNavigation_menuItemsMargin;
				padding-left: 0;
			}

			a {
				border-bottom: 1px solid @bNavigation_gray;
				color: #000000;
				padding-left: 0;
			}
		}

		// Menu links
		.menu-link {
			margin: 0 @bNavigation_menuItemsMargin;
			position: relative;

			&:before,
			&:after {
				border-bottom: 3px solid #000000;
				content: '';
				display: block;
				height: 10px;
				position: absolute;
				right: 4px;
				top: 15px;
				width: 10px;
			}

			&:before {
				transform: rotate(-45deg);
			}

			&:after {
				top: 17px;
				transform: rotate(-135deg);
			}

			&[href*='partners'] {
				&:before,
				&:after {
					display: none;
				}
			}
		}

		// Bottom button in menu
		.navigation-buttons {
			a {
				&:last-child {
					span {
						padding: 0;
						margin-right: 0.5rem;
					}
				}
			}
		}
	}
}

@media only screen and (orientation: landscape) and (max-width: 991px) {
	.navigation {
		.navigation-nav {
			.bi-nav-items.visible-lg-block {
				height: auto;
				min-height: 210vh;
			}
		}
	}
}

.bi-nav-toplink {
	display: inline-block;
	margin-top: 8px;
}
