@import '../variables/variables.less';


// padded container
.padded {
    padding-top: 80px;
    padding-bottom: 80px;
}

// .spanX grid element with defined height and vertically cenered content
.block-centered (@height) {
    @media @tablet, @desktop {
        [class*="span"] {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: @height;
        }
    }
}


