#offer-chrome {
    display: none;
    background-color: #1c2334;
  }

  #offer-chrome .AVsizer {
        padding-top: 30px;
        padding-bottom: 30px;

          margin: 0 auto;
        text-align: left;
        position: relative;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    @media only screen and (max-width: 1055px) and (min-width: 768px), only screen and (min-width: 1056px) {
        #offer-chrome .AVsizer {
            max-width: 1056px;
        }
    }

#offer-chrome .AVsizer .row {
    margin-left: -20px;
    margin-right: -20px;
}

#offer-chrome .AVsizer .row:before, .row:after {
    display: table;
    line-height: 0;
    content: "";
}

#offer-chrome .AVsizer .row:before, .row:after {
    display: table;
    line-height: 0;
    content: "";
}

#offer-chrome .AVsizer .row:after {
    clear: both;
}

#offer-chrome .AVsizer .row [class*="span"] {
    float: left;
    min-height: 1px;
    padding: 20px;
}

#offer-chrome .AVsizer .row .span4 {
    width: 33.33333332%;
}

@media only screen and (max-width: 767px){
    #offer-chrome .AVsizer .row .span4 {
        width: auto;
    }

    #offer-chrome .AVsizer .row [class*="span"] {
        float: none;
        min-height: 1px;
        padding: 20px;
        width: auto;
        display: block;
    }
}


#offer-chrome .button {
display: inline-block;
vertical-align: middle;
text-decoration: none;
cursor: pointer;
position: relative;
}

#offer-chrome .button.transparent SPAN {
color: #FFF;
background-color: transparent;
-webkit-box-shadow: 0 0 0 1px #FFF inset;
-moz-box-shadow: 0 0 0 1px #FFF inset;
box-shadow: 0 0 0 1px #FFF inset;
}

#offer-chrome .button span {
margin: 0 auto 0;
padding: 14px 20px 12px;
display: inline-block;
text-align: center;
font-weight: 700;
text-transform: uppercase;
font-size: 15px;
line-height: 16px;
background-repeat: no-repeat;
position: relative;
zoom: 1;
-webkit-border-radius: 2px;
-moz-border-radius: 2px;
border-radius: 2px;
-webkit-transition: all .1s ease-in-out;
-o-transition: all .1s ease-in-out;
-moz-transition: all .1s ease-in-out;
transition: all .1s ease-in-out;
}


  #offer-chrome P {
      color: #ffffff;
      padding: 15px 0;
  }
  #offer-chrome #logo-chrome {
      text-align: right;
  }

  #offer-chrome #logo-chrome img {
      margin: 0 auto;
  }

  #offer-chrome #button-chrome .button {
      margin: 0 auto;
  }

  @media only screen and (max-width: 767px) {
      #offer-chrome [class*="span"] {
          text-align: center !important;
      }
  }

  /* localisation */
  [class*="mod-de"] #offer-chrome P,
  [class*="mod-nl"] #offer-chrome P {
      padding-top: 7px;
  }
