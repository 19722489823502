/* Language selector */

@media (max-width: 991px) {
    .language-selector {
      padding-right: 0 !important;
    }
  }
  .language-selector .modal-dialog {
    max-width: 100%;
  }
  @media (max-width: 991px) {
    .language-selector .modal-dialog {
      margin: 0 !important;
      width: 100%;
    }
    .language-selector .modal-dialog .modal-body-outer {
      margin-bottom: 20px;
    }
    .language-selector .modal-dialog .modal-content {
      border: 0 !important;
    }
  }
  .language-selector .modal-body,
  .language-selector .modal-footer,
  .language-selector .modal-header {
    padding: 25px 10px;
    margin: 0 20px;
  }
  .language-selector .modal-header {
    padding-left: 0;
    padding-right: 0;
    margin: 0 30px;
  }
  .language-selector .selector-close {
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url("../i/avg/img/components/languageselector/close.png") no-repeat 0 0;
    cursor: pointer;
    float: right !important;
  }
  .language-selector .selector-close:hover,
  .language-selector .selector-close:active,
  .language-selector .selector-close:focus,
  .language-selector .selector-close:target {
    opacity: 0.6;
  }
  .language-selector .modal-body-outer {
    overflow-y: auto;
  }
  .language-selector .modal-body,
  .language-selector .modal-footer {
    font-size: 14px;
    text-align: left;
  }
  .language-selector .modal-body ul,
  .language-selector .modal-footer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    @media (max-width: 991px) {
        text-align: left;
      }
  }
  .language-selector .modal-body ul li,
  .language-selector .modal-footer ul li {
    padding: 4px 0;
  }
  .language-selector .modal-body ul li:before,
  .language-selector .modal-footer ul li:before,
  .language-selector .modal-body ul li:after,
  .language-selector .modal-footer ul li:after {
    content: " ";
    display: table;
  }
  .language-selector .modal-body ul li:after,
  .language-selector .modal-footer ul li:after {
    clear: both;
  }
  .language-selector .modal-body ul li:before,
  .language-selector .modal-footer ul li:before,
  .language-selector .modal-body ul li:after,
  .language-selector .modal-footer ul li:after {
    content: " ";
    display: table;
  }
  .language-selector .modal-body ul li:after,
  .language-selector .modal-footer ul li:after {
    clear: both;
  }
  .language-selector .modal-body span.icon-flag,
  .language-selector .modal-footer span.icon-flag {
    float: left !important;
    margin-right: 10px;
  }
  .language-selector .modal-body .modal-body,
  .language-selector .modal-footer .modal-body {
    max-height: 80%;
  }
  @media (max-width: 991px) {
    .language-selector .modal-footer {
      display: none;
    }
  }
