/* # BUTTONS

Styles for creating all types of buttons

Html syntax is

```<a class="button COLOR SIZE OTHER"><> SPAN>Text</> SPAN></a>```

Contains
  * general button definition
  * sizes
  * colors
  * arrows
  * special buttons

 */
 @import '../variables/variables.less';



 /* # General button definition

 Defines basic button behaviour

 * class .button
 * hover state normalization
 * basic styles for > SPAN element

  */

 .button {
     display: inline-flex;
     align-items: center;
     border-radius: 3px;
     text-decoration: none;
     cursor: pointer;
     position: relative;

     &:hover {
         text-decoration: none;
     }

     & > SPAN {
         display: inline-flex;
         justify-content: space-between;
         align-items: center;
         font-weight: 400;
         background-repeat: no-repeat;
         position: relative;
         vertical-align: middle;
         // white-space: nowrap;

		 .sticky-bar__right & {
			 body[id$="-mac"] & {
				@media @mobile, @tablet {
					gap: 4px;
				}
			 }
		 }
     }
 }

 /* ## Button sizes  */
 .button.primary {

     // styles for button with price
     &.with-price {

         & > span {
             display: flex;
             flex-direction: column;
             align-items: center;
             justify-content: center;
         }
         i {
             display: block;
             text-align: center;
         }
     }

     // SMALL
     &.small > span {
         border-radius: 5px;
         padding: 6px 24px;
         font-size: 16px;
         height: 48px;
         line-height: 1;
     }
     &.small.with-price > span {
         padding-top: 6px;
         padding-bottom: 6px;

         i {
             font-style: normal;
             font-size: 14px;
             line-height: 18px;
             padding-top: 3px;
             font-weight: normal;
         }
     }

     // MEDIUM
     &.medium > span {
         border-radius: 5px;
         padding: 19px 24px;
         font-size: 18px;
         line-height: 1;
         height: 56px;
     }

     // LARGE
     &.large > span {
         border-radius: 6px;
         padding: 20px 40px;
         font-size: 20px;
         height: 72px;
         line-height: 0.85;
         @media @mobile {
            padding: 14px 24px;
           }
     }
     &.large.with-price > span {
         padding-top: 6px;
         padding-bottom: 6px;

         i {
             font-style: normal;
             font-size: 16px;
             line-height: 24px;
             padding-top: 5px;
             font-weight: normal;
         }
     }
 }

 /* ## Button variants  */

 /* ## Primary */
 .button.primary > SPAN {
     font-weight: 700;
 }

 /* ### Green */
 // Usually used for free downloads
 .button.primary.green {

    & > SPAN {
        background-color: @greenButtonBgColor-v2;
        color: @primaryButtonColor;

        &.text-large {
            @media @mobile {
                font-size: 16px;
               }
        }
    }
    &:hover > SPAN {
        background-color: @greenButtonBgColorHover-v2;
        color: @primaryButtonColor;
        box-shadow: 0px 20px 40px -20px rgba(12, 5, 62, 0.25), 0px 20px 40px -20px rgba(0, 137, 65, 0.95);
    }
    &.shadow > SPAN {
        box-shadow: 0 10px 30px -10px @greenButtonBgColor-v2;
    }

    // outline
    &.outline > SPAN {
        color: @greenButtonBgColor-v2;
    //  background-color: @primaryButtonColor;
    background-color: transparent;
        border: 1px solid @greenButtonBgColor-v2;
    }
    &.outline:hover > SPAN {
        background-color: @greenButtonBgColorHover-v2;
        color: @primaryButtonColor;
        box-shadow: 0px 20px 40px -20px rgba(12, 5, 62, 0.25), 0px 20px 40px -20px rgba(0, 137, 65, 0.95);
    }
    // outline inverted
    &.outline.inv > SPAN {
        color: @primaryButtonColor;
        background-color: transparent;
        border: 1px solid @greenButtonBgColor-v2;
    }
    &.outline.inv:hover > SPAN {
        background-color: @primaryButtonColor;
        color: @greenButtonBgColor-v2;
    }

    .icons {
        background-position: 12% 48%;
        background-size: 24px 24px;
        top: calc(50% - 11px);

        @media @mobile {
            background-position: 15% 48%;
        }
    }

    &.icon-win > SPAN {
        background-image:  url('../i/buttons/win-white.svg');
        .icons;
    }

    &.icon-mac > SPAN {
        background-image:  url('../i/buttons/mac-white.svg');
        .icons;
    }

    &.icon-android > SPAN {
        background-image:  url('../i/buttons/android-white.svg');
        .icons;
    }

    &.icon-ios > SPAN {
        background-image:  url('../i/buttons/ios-white.svg');
        .icons;
    }    

    &.icon-left {
        &.medium {
            span {
              padding-left: 70px;
            }
        }
    }
}
 /* ### Blue */
 // Usually used for cart links
  .button.primary.blue {

     & > SPAN {
         background-color: @blueButtonBgColor;
         color: @primaryButtonColor;
     }
     &:hover > SPAN {
         background-color: @blueButtonBgColorHover;
         color: @primaryButtonColor;
     }
     &.shadow > SPAN {
         box-shadow: 0 10px 30px -10px @blueButtonBgColor;
     }

     // outline
     &.outline > SPAN {
         color: @blueButtonBgColor;
         background-color: @primaryButtonColor;
         border: 1px solid @blueButtonBgColor;
     }
     &.outline:hover > SPAN {
         background-color: @blueButtonBgColorHover;
         color: @primaryButtonColor;
     }
     // outline inverted
     &.outline.inv > SPAN {
         color: @primaryButtonColor;
         background-color: transparent;
         border: 1px solid @primaryButtonColor;
     }
     &.outline.inv:hover > SPAN {
         background-color: @primaryButtonColor;
         color: @blueButtonBgColor;
     }

    .icons {
        background-position: 9% 48%;
        background-size: 24px 24px;
        top: calc(50% - 11px);
    }

    &.icon-win > SPAN {
        background-image:  url('../i/buttons/win-white.svg');
        .icons;
    }

    &.icon-mac > SPAN {
        background-image:  url('../i/buttons/mac-white.svg');
        .icons;
        background-position: 9% 46%;
    }

    &.icon-android > SPAN {
        background-image:  url('../i/buttons/android-white.svg');
        .icons;
    }

    &.icon-ios > SPAN {
        background-image:  url('../i/buttons/ios-white.svg');
        .icons;
    }

    &.icon-phone > SPAN {
        background-image:  url('../i/buttons/phone-white.svg');
        .icons;
        background-position: 12% 48%;
    } 
 }

 /* ### White */
 // Usually used on blue/green background
 .button.primary.white {

    & > SPAN {
        background-color: @primaryButtonColor;
        color: @greenButtonBgColor-v2;
    }
    &:hover > SPAN {
        background-color: @White;
        color: @greenButtonBgColor-v2;
        opacity: 0.9;
    }
    &.shadow > SPAN {
        box-shadow: 0 10px 30px -10px @greenButtonBgColor-v2;
    }

    // outline
    &.outline > SPAN {
        color: @primaryButtonColor;
        background-color: transparent;
        border: 1px solid @primaryButtonColor;
    }
    &.outline:hover > SPAN {
        background-color: @primaryButtonColor;
        color: @blueButtonBgColor;
    }

    .icons {
        background-position: 9% 50%;
        background-size: 24px 24px;
        top: calc(50% - 11px);
    }

    &.icon-win > SPAN {
        background-image:  url('../i/buttons/facelift/icon-win-green.svg');
        .icons;
    }

    &.icon-mac > SPAN {
        background-image:  url('../i/buttons/facelift/icon-mac-green.svg');
        .icons;
    }

    &.icon-android > SPAN {
        background-image:  url('../i/buttons/facelift/icon-android-green.svg');
        .icons;
        background-position: 14% 50%;
    }

    &.icon-ios > SPAN {
        background-image:  url('../i/buttons/facelift/icon-ios-green.svg');
        .icons;
    }
}


 /* ## Secondary (for less important links, to be used on dark backgrounds) */
 .button.secondary > SPAN {
     color: @HyperlinkText;
     background-color: @Transparent;
     font-size: 16px;
     font-weight: 700;
     line-height: 21px;
     border-bottom: 1px dotted @HyperlinkText;

     &:hover {
         color: @HyperlinkText;
     }
 }

 /* # Buttons with symbols */

 .button {

     // mixin for button
     .with-icon {
         padding-right: 80px;
     }
     .with-icon-left-primary {
         padding-left: 78px;
         @media @mobile {
            padding-left: 70px;
           }
     }
     .with-icon-left-medium {
         padding-left: 57px;
     }

     // mixins for icon
     .icon {
         position: absolute;
         right: 20px;
         top: calc(50% - 10px);
         display: inline-block;
         vertical-align: middle;
         content: "";
         background-repeat: no-repeat;
     }
     .icon-l {
         left: 20px;
         right: 0;
     }
     .vertical {
         width: 20px;
         height: 20px;
         margin-right: 20px;
     }
     .horizontal {
         width: 40px;
         height: 20px;

     }

     &.primary {
         /* Download button */
         // centered moves down to the white line
         @keyframes primaryDownload {
             from { background-position: 50% 50%; }
             to { background-position: 50% 500%; }
         }
         &.download > SPAN {
             .with-icon;
         }
         &.download > SPAN::after {
             .icon;
             .vertical;
             border-bottom: 2px solid @White;
             background-image:  url('../i/buttons/primary-download.png');
             background-position: 50% 50%;
         }
         &.download:hover > SPAN::after {
             // animation: primaryDownload 0.5s linear infinite;
         }
         /* Buy button */
         // centered, moves right
         @keyframes primaryBuy {
             from { background-position: 0% 50%; }
             to { background-position: 100% 50%; }
         }

         &.buy > SPAN {
             .with-icon;
         }
         &.buy > SPAN::after {
             .icon;
             .horizontal;
             background-image:  url('../i/buttons/primary-buy.png');
             background-position: 0% 50%;
         }
         &.buy:hover > SPAN::after {
             // animation: primaryBuy 0.5s linear infinite;
         }

         /* Button with icon left */
         &.icon-left.primary > SPAN {
             .with-icon-left-primary;
         }

         &.icon-left.medium > SPAN {
             .with-icon-left-medium;
         }

         &.icon-left > SPAN::after {
             .icon;
             .icon-l;
         }

         .browser-icons {
             background-position: 50% 50%;
             width: 32px;
             height: 32px;
             top: calc(50% - 15px);
         }

         &.icon-chrome > SPAN::after {
             background-image:  url('../i/buttons/chrome-white.svg');
             .browser-icons;
         }

         &.icon-firefox > SPAN::after {
             background-image:  url('../i/buttons/firefox-white.svg');
             .browser-icons;
         }

         &.icon-edge > SPAN::after {
             background-image:  url('../i/buttons/edge-white.svg');
             .browser-icons;
         }

         &.icon-opera > SPAN::after {
             background-image:  url('../i/buttons/opera-white.svg');
             .browser-icons;
         }

         &.outline {
             &.icon-chrome,
             &.icon-firefox,
             &.icon-edge,
             &.icon-opera {
                 & > SPAN::after{
                     background: none;
                 }
             }

            .browser-icons-outline {
                background-position: 9% 50%;
                background-size: 24px 24px;
                top: calc(50% - 11px);
                padding-left: 50px;
            }

             &.icon-chrome > SPAN {
                 background-image:  url('../i/buttons/chrome-green.svg');
                 .browser-icons-outline;

                 &:hover {
                     background-image:  url('../i/buttons/chrome-white.svg');
                 }
             }

             &.icon-firefox > SPAN {
                 background-image:  url('../i/buttons/firefox-green.svg');
                 .browser-icons-outline;

                 &:hover {
                     background-image:  url('../i/buttons/firefox-white.svg');
                 }
             }

             &.icon-edge > SPAN {
                 background-image:  url('../i/buttons/edge-green.svg');
                 .browser-icons-outline;

                 &:hover {
                     background-image:  url('../i/buttons/edge-white.svg');
                 }
             }

             &.icon-opera > SPAN {
                 background-image:  url('../i/buttons/opera-green.svg');
                 .browser-icons-outline;

                 &:hover {
                     background-image:  url('../i/buttons/opera-white.svg');
                 }
             }
             &.icon-win > SPAN {
                background-image:  url('../i/buttons/win-green.svg');
                .browser-icons-outline;

                &:hover {
                    background-image:  url('../i/buttons/win-white.svg');
                }
            }

            &.icon-mac > SPAN {
                background-image:  url('../i/buttons/mac-green.svg');
                .browser-icons-outline;
                background-position: 10% 46%;
                &:hover {
                    background-image:  url('../i/buttons/mac-white.svg');
                }
            }
            &.icon-android > SPAN {
                background-image:  url('../i/buttons/android-green.svg');
                .browser-icons-outline;

                background-position: 10% 46%;
                &:hover {
                    background-image:  url('../i/buttons/android-white.svg');
                }
            }
         }

     }
 }
