/* Flag icons */

// Used in header and footer
.icon-flag {
    background-image: url("../i/avg/img/components/languageselector/flags.png");
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
    display: inline-block;
    margin-top: -1px;
    background-position: -720px 0;
  }

  .icon-flag.icon-flag-ar {
    background-position: 0px 0;
  }
  .icon-flag.icon-flag-au {
    background-position: -24px 0;
  }
  .icon-flag.icon-flag-br {
    background-position: -48px 0;
  }
  .icon-flag.icon-flag-ca {
    background-position: -72px 0;
  }
  .icon-flag.icon-flag-ch {
    background-position: -96px 0;
  }
  .icon-flag.icon-flag-cz {
    background-position: -120px 0;
  }
  .icon-flag.icon-flag-de {
    background-position: -144px 0;
  }
  .icon-flag.icon-flag-eu {
    background-position: -168px 0;
  }
  .icon-flag.icon-flag-fr {
    background-position: -192px 0;
  }
  .icon-flag.icon-flag-gb {
    background-position: -216px 0;
  }
  .icon-flag.icon-flag-id {
    background-position: -240px 0;
  }
  .icon-flag.icon-flag-ie {
    background-position: -264px 0;
  }
  .icon-flag.icon-flag-in {
    background-position: -288px 0;
  }
  .icon-flag.icon-flag-ir {
    background-position: -312px 0;
  }
  .icon-flag.icon-flag-it {
    background-position: -336px 0;
  }
  .icon-flag.icon-flag-jp {
    background-position: -360px 0;
  }
  .icon-flag.icon-flag-kr {
    background-position: -384px 0;
  }
  .icon-flag.icon-flag-mx {
    background-position: -408px 0;
  }
  .icon-flag.icon-flag-my {
    background-position: -432px 0;
  }
  .icon-flag.icon-flag-nl {
    background-position: -456px 0;
  }
  .icon-flag.icon-flag-pl {
    background-position: -480px 0;
  }
  .icon-flag.icon-flag-pt {
    background-position: -504px 0;
  }
  .icon-flag.icon-flag-ru {
    background-position: -528px 0;
  }
  .icon-flag.icon-flag-sk {
    background-position: -552px 0;
  }
  .icon-flag.icon-flag-es {
    background-position: -576px 0;
  }
  .icon-flag.icon-flag-tr {
    background-position: -600px 0;
  }
  .icon-flag.icon-flag-tw {
    background-position: -624px 0;
  }
  .icon-flag.icon-flag-us {
    background-position: -648px 0;
  }
  .icon-flag.icon-flag-vn {
    background-position: -672px 0;
  }
  .icon-flag.icon-flag-za {
    background-position: -696px 0;
  }
  .icon-flag.icon-flag-ww {
    background-position: -720px 0;
  }
  .icon-flag.icon-flag-se {
    background-position: -744px 0;
  }
  .icon-flag.icon-flag-no {
    background-position: -768px 0;
  }
  .icon-flag.icon-flag-dk {
    background-position: -792px 0;
  }
  .icon-flag.icon-flag-cn {
    background-position: -816px 0;
  }
  .icon-flag.icon-flag-cl {
    background-position: -840px 0;
  }
  .icon-flag.icon-flag-co {
    background-position: -864px 0;
  }
  .icon-flag.icon-flag-be {
    background-position: -888px 0;
  }
  .icon-flag.icon-flag-nz {
    background-position: -912px 0;
  }
  .icon-flag + span {
    padding-left: 32px;
  }
